import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {JobService} from "@services/JobService";

export function Dashboard() {
    const navigate = useNavigate();

    const [totalPaid, setTotalPaid] = useState<number>();
    const [totalPrice, setTotalPrice] = useState<number>();
    const [jobNumber, setJobNumber] = useState<number>();
    const [unpaid, setUnpaid] = useState<number>();
    const [percentage, setPercentage] = useState<number>();

    useEffect( () => {
        let monthAgo = new Date();
        monthAgo.setMonth(monthAgo.getMonth() - 1);
        JobService.getAll({
            query:{
                createdAt: {
                    $gte: monthAgo
                },
                saved: true
            },
            option: {}
        })
        .then((resp: any) => {
            setTotalPaid(resp.data.totalPaid);
            setTotalPrice(resp.data.totalPrice);
            setJobNumber(resp.data.totalDocs);
            setUnpaid(resp.data.unpaid);
            setPercentage(resp.data.totalPaid*100/resp.data.totalPrice);
        })
        .catch((err: any) => {
            console.error(err);
        });
    }, []);

    return (
        <div>
            <div className="content">
                <h6 className='title title-margin text-left' >Operazioni rapide</h6>
                    <div className="flex items-start space-x-4 mt-5 overflow-x-auto">
                        <a onClick={() => navigate("/customers/manage")} className=" flex items-center justify-around btn btn-lg btn-primary rounded-full gap-2 text-white">
                            <i className="fa-solid fa-user-plus"></i>Nuovo cliente
                        </a>
                        <a onClick={() => navigate("/plates/add")} className=" flex items-center justify-around btn btn-lg btn-primary rounded-full gap-2 text-white">
                            <i className="fa-solid fa-file-circle-plus"></i>Nuova lastra
                        </a>
                        <a onClick={() => navigate("/plates")} className=" flex items-center justify-around btn btn-lg btn-primary rounded-full gap-2 text-white">
                            <i className="fa-solid fa-clock-rotate-left"></i>Storico lastre
                        </a>
                    </div>
                <h6 className='title title-margin text-left mt-10' >Dashboard</h6>
                <div className='flex items-center gap-10 mt-5'>
                    <div className="w-80 rounded-xl shadow-md bg-base-100">
                        <div className="select-none duration-500 ease-in-out rounded-xl h-20 flex flex-col items-center justify-center min-w-min max-w-[100%] bg-secondary text-white" style={{width: "calc("+percentage/100+"*20rem)"}}>
                            <div className="mx-1 text-2xl">
                                €{totalPaid}
                            </div>
                            <div className="mx-1 text-xs">
                                consegnati
                            </div>
                        </div>
                    </div>
                    <div className="text-2xl">
                        questo mese
                    </div>
                </div>
                <div className='flex items-center gap-10 mt-5'>
                        <div className="select-none rounded-xl h-20 w-20 flex items-center justify-around bg-secondary text-white">
                            <div className="m-1 text-2xl">
                                {jobNumber}
                            </div>
                        </div>
                    <div className="text-2xl">
                        nuovi lavori questo mese
                    </div>
                </div>
                <div className='flex items-center gap-10 mt-5'>
                    <div className="select-none rounded-xl h-20 w-20 flex items-center justify-around bg-warning text-white">
                        <div className="m-1 text-2xl">
                            {unpaid}
                        </div>
                    </div>
                    <div className="text-2xl">
                        lavori non consegnati questo mese
                    </div>
                </div>
            </div>
        </div>
    );
}
