    import {useSearchParams} from "react-router-dom";
    import React, {useEffect, useState} from "react";
    import {Customer, Job} from "@models";
    import {JobService} from "@services/JobService";
    import {CustomerService} from "@services/CustomerService";
    import {useForm} from "react-hook-form";
    import dateFormat from "dateformat";
    import InfiniteScroll from "react-infinite-scroll-component";
    import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@mui/material";
    import {ParentJob} from "../models/ParentJob";
    import {ExportPDF} from "@utils/pdf/ExportPDF";
    import * as pdfMake from "pdfmake/build/pdfmake";
    import {Popover} from "@mui/material";

    export function CustomerJobs() {

        const [jobs, setJobs] = useState<Job[]>([]);
        const [customer, setCustomer] = useState<Customer>();
        const [searchParams, setSearchParams] = useSearchParams();
        const { reset, formState: { errors } } = useForm();
        const customerId = searchParams.get("id");
        const [hasMore, setHasMore] = useState<boolean>(true);
        const [pages, setPages] = useState<number>();

        const [isExportingJobs, setIsExportingJobs] = useState<boolean>(false)
        const [exportingJobs, setExportingJobs] = useState<ParentJob[]>([]);
        const [openAssociateJobsDialog, setOpenAssociateJobsDialog] = useState<boolean>(false)
        const [fatherJob, setFatherJob] = useState<Job>();
        const [ddtNumber, setDDTNumber] = useState<number>()
        const [hasMargin, setHasMargin] = useState<boolean>(true)

        let page = 1;

        const [totalPaid, setTotalPaid] = useState<number>();
        const [totalPrice, setTotalPrice] = useState<number>();
        const [unpaid, setUnpaid] = useState<number>();
        const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
        const open = Boolean(anchorEl);
        const [popoverJob, setPopoverJob] = useState<Job>();

        const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>, job : Job) => {
            setPopoverJob(job)
            setAnchorEl(event.currentTarget);
        };

        const handlePopoverClose = () => {
            setAnchorEl(null);
            setPopoverJob(null)
        };

        useEffect( () => {
            JobService.getByCustomer(customerId, page)
                .then((resp: any) => {
                    const jobs = resp.data['docs'] as Job[];
                    setTotalPaid(resp.data.totalPaid);
                    setTotalPrice(resp.data.totalPrice);
                    setJobs(jobs);
                })
                .catch((err: any) => {
                    console.error(err);
                });

            CustomerService.get({_id: customerId})
                .then((resp: any) => {
                    const customer = resp.data['docs'][0] as Customer;
                    setCustomer(customer);
                })
                .catch((err: any) => {
                    console.error(err);
                });
        }, []);

        useEffect( () => {
            console.log("second useEffect");
            reset({ ...jobs });
        }, [jobs]);


        useEffect( () => {
            console.log("third useEffect");
            calculatePrice();
        }, [totalPrice, totalPaid]);

        const calculatePrice = () => {
            console.log("calculate price");
            JobService.getByCustomer(customerId, 1)
                .then((resp: any) => {
                    setTotalPaid(resp.data.totalPaid);
                    setTotalPrice(resp.data.totalPrice);
                    setUnpaid(resp.data.unpaid);
                    setPages(resp.data.totalPages);

                })
                .catch((err: any) => {
                    console.error(err);
                });
        };


        const getNextJobs = () =>  {
            console.log("get next jobs");
            if (page >= pages) {
                setHasMore(false);
                return;
            }
            else {
            JobService.getByCustomer(customerId, ++page)
                .then((resp: any) => {
                    const jobs = resp.data['docs'] as Job[];
                    setJobs(jobs);
                })
                .catch((err: any) => {
                    console.error(err);
                });
            }
        }

        return (
            <div>
                <Popover
                    id="job-info"
                    sx={{
                        pointerEvents: "none",
                        marginBottom : 100,
                        display : !!popoverJob ? "block" : "none"
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: 70,
                        horizontal: "center",
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <div className="p-4 flex">
                        <span className="font-bold mr-2">N° Copie :</span><span className="mr-2"> {popoverJob?.copies}</span>
                        <span className="font-bold mr-2">| {popoverJob?.paid ? "Consegnato" : "Non consegnato"}</span>
                        <span className="font-bold mr-2">| Costo disegno : </span><span className="mr-2">€ {popoverJob?.drawingPrice}</span>
                        <span className="font-bold mr-2">| Prezzo : </span><span className="mr-2">€ {popoverJob?.price}</span>
                    </div>
                </Popover>
                <Dialog
                    onClose={() => {
                        setFatherJob(null);
                        setOpenAssociateJobsDialog(false);
                    }}
                    open={openAssociateJobsDialog}
                    sx={{
                        "& .MuiDialog-container": {
                            "& .MuiPaper-root": {
                                width: "70%",
                                maxWidth: "70vh",
                            },
                        },
                    }}
                >
                    <DialogTitle>
                        <div className="flex justify-between">
                            <span className="font-semibold text-3xl">SCEGLIERE LAVORO FIGLIO</span>
                            <i className="fa-solid fa-x fa-l cursor-pointer" onClick={() => {
                                setFatherJob(null);
                                setOpenAssociateJobsDialog(false);
                            }}></i>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description" className="text-xl">
                            <div className="flex justify-start items-center mb-5">
                                <img
                                    src={`${process.env.REACT_APP_SERVER_URL}/${process.env.REACT_APP_SERVER_IMAGES}/${fatherJob?.file.name}`}
                                    alt="Loaded job"
                                    className="w-20 h-20 py-2 rounded-full border-2 border-gray-200"/>
                                    <span className="font-bold text-2xl ml-4">{fatherJob?.name}</span>
                            </div>
                            {exportingJobs.filter(job => job.fatherJob !== fatherJob && !(!!job.childrenJob)).map(exportingJob => {
                                return (
                                    <div className="mb-4 rounded-lg bg-base-300">
                                        <div className="p-2  flex justify-between items-center">
                                            <div className="flex items-center">
                                                <img
                                                    src={`${process.env.REACT_APP_SERVER_URL}/${process.env.REACT_APP_SERVER_IMAGES}/${exportingJob.fatherJob.file.name}`}
                                                    alt="Loaded job"
                                                    className="w-20 h-20 py-2 bg-white rounded-full border-2 border-gray-200"/>
                                                <div className="ml-5">
                                                    <span className="font-bold text-2xl">{exportingJob.fatherJob.name}</span>
                                                    <span className="flex text-xl">{exportingJob.fatherJob.width}x{exportingJob.fatherJob.height}cm</span>
                                                </div>
                                            </div>
                                            <button onClick={() => {
                                                setFatherJob(null)
                                                setOpenAssociateJobsDialog(false);
                                                exportingJobs.find(job => job.fatherJob === fatherJob).childrenJob = exportingJob.fatherJob
                                                setExportingJobs(exportingJobs.filter(job => job !== exportingJob))
                                            }} type="button" className="btn-secondary text-white flex items-center justify-around btn btn-lg btn-primary">
                                                <i className="fa-solid fa-arrow-turn-up"></i>
                                            </button>
                                        </div>
                                    </div>
                                )
                            })}
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <div className="content">
                    <div className="flex items-center justify-between">
                        <div>
                            <div
                                className="title appearance-none bg-transparent mr-3 py-1 leading-tight ">
                                {customer?.customerCode} | {customer?.businessName}
                            </div>
                        </div>
                        <div className="flex ">
                            {isExportingJobs ? (
                                <button onClick={() => {
                                    window.location.reload()
                                }} type="button" className="btn-error text-white flex items-center justify-around btn btn-lg btn-primary rounded-full gap-2 mr-2">
                                    <i className="fa-solid fa-x"></i>
                                </button>
                            ) : null}
                            <button onClick={() => {
                                if (isExportingJobs){
                                    pdfMake.createPdf(ExportPDF(exportingJobs, ddtNumber, customer?.businessName, hasMargin)).download(`DDT_${customer.businessName}_${new Date().toJSON().slice(0,10).replace(/-/g,"")}`, function () {window.location.reload()});
                                }
                                setIsExportingJobs(true)
                            }} disabled={(exportingJobs.length === 0 || !!!ddtNumber) && isExportingJobs} type="button" className="btn-primary text-white flex items-center justify-around btn btn-lg btn-primary rounded-full gap-2 mr-10">
                                <i className="fa-solid fa-file-export"></i> Esporta
                            </button>
                        </div>
                    </div>
                    {isExportingJobs ?
                        <div className="flex flex-row w-full gap-5">
                            <div className="flex flex-col h-20 justify-between w-1/6">
                                <span className="font-bold text-xl flex justify-start m-2">Numero DDT</span>
                                <input id="ddtNumber"
                                       className="input input-xs rounded-full h-12"
                                       type="number" min={1}  onChange={(evt) => {
                                    setDDTNumber(parseInt(evt.target.value))
                                }}
                                />
                            </div>

                            <div className="flex flex-col w-1/6 h-20 justify-between items-center">
                                <span className="font-bold text-xl flex justify-start m-2">Mostra margini</span>
                                <label htmlFor="show-margin" className="inline-flex relative items-center cursor-pointer">
                                    <input type="checkbox" defaultChecked={!!hasMargin}
                                           id="show-margin" className="sr-only peer"
                                           onChange={() => {
                                               setHasMargin(!!!hasMargin)
                                           }}/>
                                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:shadow-md bg-base-100 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"/>
                                </label>
                            </div>
                        </div>
                        : ""
                    }
                    <div className="flex justify-between">
                        <div className={isExportingJobs ? "w-1/2" : "w-full"}>
                            <span className="font-bold text-2xl flex justify-start m-2">Elenco Lavori</span>
                            <InfiniteScroll
                                next={getNextJobs}
                                hasMore={hasMore}
                                loader={
                                    <button onClick={getNextJobs} className="mt-5 btn btn-ghost">Carica altri lavori</button>
                                }
                                dataLength={10}
                                scrollableTarget="scrollableDiv"
                                className=" rounded-xl">
                                <div className="shadow-md bg-base-100/50 pb-10 h-scroll-3 rounded-xl" id="scrollableDiv">
                                    {jobs.map((job, index) => {
                                        return (
                                            <div key={index} className="shadow-md bg-base-100/50 m-3 rounded-lg">
                                                <div className="p-2 flex justify-between items-center ">
                                                    <div className="flex items-center">
                                                        <img
                                                            src={`${process.env.REACT_APP_SERVER_URL}/${process.env.REACT_APP_SERVER_IMAGES}/${job.file.name}`}
                                                            alt="Loaded job"
                                                            className="w-20 h-20 py-2 bg-white rounded-full border-2 border-gray-200"/>
                                                        <div className="ml-5">
                                                            <span className="font-bold text-2xl">{job.name}</span>
                                                            <span className="flex text-xl">{job.width}x{job.height}cm</span>
                                                        </div>
                                                    </div>
                                                    {isExportingJobs ? (
                                                        <div className="flex items-center justify-between">
                                                            <div>
                                                                <Typography
                                                                    aria-owns={open ? 'job-info' : undefined}
                                                                    aria-haspopup="true"
                                                                    onMouseEnter={(evt) => handlePopoverOpen(evt,job)}
                                                                    onMouseLeave={handlePopoverClose}
                                                                >
                                                                    <i className="fa-solid fa-circle-info fa-xl mr-4 cursor-pointer"></i>
                                                                </Typography>
                                                            </div>
                                                            <button onClick={() => {
                                                                setExportingJobs([...exportingJobs, {fatherJob : job}])
                                                                setJobs([...jobs.filter(j => j != job)])
                                                            }} type="button" className={isExportingJobs ? "btn-secondary text-white flex items-center justify-around btn btn-lg btn-primary" : "hidden"}>
                                                                <i className="fa-sharp fa-solid fa-arrow-right"></i>
                                                            </button>
                                                        </div>
                                                    ) : (
                                                        <div className="flex items-center justify-between">
                                                            <div className="flex flex-col items-center mr-3">
                                                                <span className="font-bold mb-1">Consegnato</span>
                                                                <label htmlFor={"paid-toggle_" + index} className="inline-flex relative items-center cursor-pointer">
                                                                    <input type="checkbox" defaultChecked={job.paid}
                                                                           id={"paid-toggle_" + index} className="sr-only peer"
                                                                           onChange={() => {
                                                                               JobService.setPaid(job._id, !job.paid);
                                                                               job.paid = !job.paid;
                                                                               calculatePrice();
                                                                           }}/>
                                                                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:shadow-md bg-base-100 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"/>
                                                                </label>
                                                            </div>
                                                            <span className="text-2xl font-bold mr-2">€ {job.price}</span>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        )})}
                                </div>
                            </InfiniteScroll>
                        </div>
                        <div className={isExportingJobs ? "w-1/2 ml-5" : "hidden"}>
                            <div className="flex justify-between">
                                <div className="flex w-1/2">
                                    <span className="font-bold text-2xl flex m-2">Esportati</span>
                                </div>
                                <span className="text-2xl flex m-2">€{exportingJobs.reduce(((a, b) => a + b.fatherJob.price),0)}</span>
                            </div>
                            <div className="shadow-md bg-base-100/50 pb-10 h-scroll-3 rounded-xl">
                                {exportingJobs.map(exportingJob => {
                                    return (
                                        <div className="m-3 shadow-md bg-base-100/50 rounded-lg">
                                            <div className="p-2 flex justify-between items-center">
                                                <div className="flex items-center">
                                                    <img
                                                        src={`${process.env.REACT_APP_SERVER_URL}/${process.env.REACT_APP_SERVER_IMAGES}/${exportingJob.fatherJob.file.name}`}
                                                        alt="Loaded job"
                                                        className="w-20 h-20 py-2 bg-white rounded-full border-2 border-gray-200"/>
                                                    <div className="ml-5">
                                                        <span className="font-bold text-2xl">{exportingJob.fatherJob.name}</span>
                                                        <span className="flex text-xl">{exportingJob.fatherJob.width}x{exportingJob.fatherJob.height}cm</span>
                                                    </div>
                                                </div>
                                                <div className="flex items-center justify-between">
                                                    <div>
                                                        <Typography
                                                            aria-owns={open ? 'job-info' : undefined}
                                                            aria-haspopup="true"
                                                            onMouseEnter={(evt) => handlePopoverOpen(evt,exportingJob.fatherJob)}
                                                            onMouseLeave={handlePopoverClose}
                                                        >
                                                            <i className="fa-solid fa-circle-info fa-xl mr-4 cursor-pointer"></i>
                                                        </Typography>
                                                    </div>
                                                    <button onClick={() => {
                                                        setExportingJobs([...exportingJobs.filter(j => j != exportingJob)])
                                                        if (!!exportingJob.childrenJob) {
                                                            setJobs([...jobs, exportingJob.fatherJob, exportingJob.childrenJob])
                                                        } else {
                                                            setJobs([...jobs, exportingJob.fatherJob])
                                                        }
                                                    }} type="button" className={isExportingJobs ? "flex justify-end btn-warning text-white flex items-center justify-around btn btn-lg btn-primary" : "hidden"}>
                                                        <i className="fa-sharp fa-solid fa-arrow-left"></i>
                                                    </button>
                                                </div>
                                            </div>
                                            {!!!exportingJob.childrenJob ?
                                                (exportingJobs.filter(job => !!!job.childrenJob).length > 1 ? (<div className="w-full rounded-b-lg">
                                                    <span className="text-secondary text-xl flex justify-start mx-5 underline cursor-pointer w-fit " onClick={() => {
                                                        setOpenAssociateJobsDialog(true)
                                                        setFatherJob(exportingJob.fatherJob)
                                                    }}>Agguingi lavoro al foglio</span>
                                                </div>) : null) : (
                                                    <div className="p-3">
                                                        <div className="shadow-md bg-base-100/50 rounded-lg">
                                                            <div className="p-2 flex justify-between items-center">
                                                                <div className="flex items-center">
                                                                    <img
                                                                        src={`${process.env.REACT_APP_SERVER_URL}/${process.env.REACT_APP_SERVER_IMAGES}/${exportingJob.childrenJob.file.name}`}
                                                                        alt="Loaded job"
                                                                        className="w-20 h-20 py-2 bg-white rounded-full border-2 border-gray-200"/>
                                                                    <div className="ml-5">
                                                                        <span className="font-bold text-2xl">{exportingJob.childrenJob.name}</span>
                                                                        <span className="flex text-xl">{exportingJob.childrenJob.width}x{exportingJob.childrenJob.height}cm</span>
                                                                    </div>
                                                                </div>
                                                                <div className="flex items-center justify-between">
                                                                    <div>
                                                                        <Typography
                                                                            aria-owns={open ? 'job-info' : undefined}
                                                                            aria-haspopup="true"
                                                                            onMouseEnter={(evt) => handlePopoverOpen(evt,exportingJob.childrenJob)}
                                                                            onMouseLeave={handlePopoverClose}
                                                                        >
                                                                            <i className="fa-solid fa-circle-info fa-xl mr-4 cursor-pointer"></i>
                                                                        </Typography>
                                                                    </div>
                                                                    <button onClick={() => {
                                                                        setExportingJobs([...exportingJobs, {fatherJob : exportingJob.childrenJob}])
                                                                        exportingJobs.find(job => job.fatherJob === exportingJob.fatherJob).childrenJob = null;
                                                                    }} type="button" className={isExportingJobs ? "flex justify-end btn-warning text-white flex items-center justify-around btn btn-lg btn-primary" : "hidden"}>
                                                                        <i className="fa-solid fa-arrow-turn-up scale-x-[-1] rotate-[270deg]"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
