import {Job} from "@models";
import React, {useState} from "react";
import {JobService} from "@services/JobService";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";


export const PrintSavedJobs = (props :  any) => {

    const { jobs, setJobs } = props;
    const [open, setOpen] = React.useState(false);
    const [deletingId, setDeletingId] = useState<string>();

    const handleClose = () => {
        setOpen(false);
        setDeletingId(null);
    };

    const handleRemoveSavedJob = () => {
        setJobs(jobs.filter((j : Job) => j._id !== deletingId));
        JobService.safeDelete(deletingId).then(r => {
            //TO-DO In seguito agguingere delle notifiche in base allo stato della richiesta
            handleClose()
        });
    }

    const handleRemoveJob = (job: string) => {
        JobService.delete(job).then(r => {
            setJobs(jobs.filter((j : Job) => j._id !== job));
        });
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                style={{
                    borderRadius: 2
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    Sicuro di voler eliminare?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Questa operazione eliminerà il lavoro dalla lista.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleClose} className="flex items-center justify-around btn bg-transparent hover:bg-transparent rounded-full text-secondary border-0 py-2 px-4">
                        Annulla
                    </button>
                    <button onClick={handleRemoveSavedJob} className="flex items-center justify-around btn btn-error rounded-full bg-warning text-white border-0 py-2 px-4">
                        Elimina
                    </button>
                </DialogActions>
            </Dialog>
            {jobs?.map((job : Job) => {
            return (
                <div className="mt-10 p-10 shadow-md bg-base-100 rounded-lg">
                    {
                        ((window.location.href).includes("plates/add")) ?
                            (
                                <div className="flex flex-row items-center justify-end">
                                    <button onClick={() => handleRemoveJob(job._id)}
                                            className="flex items-center justify-around btn btn-error rounded-full bg-warning text-white border-0 py-2 px-4">
                                        Elimina
                                    </button>
                                </div>
                            )
                            : ""
                    }
                    <div className="flex justify-between items-center">
                        <div className="flex items-center">
                            <img src={`${process.env.REACT_APP_SERVER_URL}/${process.env.REACT_APP_SERVER_IMAGES}/${job.file.name}`} alt="Loaded job"
                                 className="p-2 px-6 w-48 bg-white rounded-full border-2 border-gray-200"/>
                            <div className="text-left text-xs ml-3">
                                <div className="flex items-center gap-2">
                                    <i className="fa-solid fa-user fa-2x"></i> &nbsp;
                                    <span className="title appearance-none py-1 leading-tight focus:outline-none">
                                        {job.customer.customerCode} | {job.customer.businessName}
                                    </span>
                                </div>
                                <div className="flex items-center gap-2">
                                    <i className="fa-solid fa-pen fa-2x"></i> &nbsp;
                                    <span className="title appearance-none py-1 leading-tight focus:outline-none">
                                        {job.name}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="flex items-end">
                            <span className="font-bold text-6xl">€ {job.price}</span>
                        </div>
                    </div>
                    <div className="flex justify-between gap-4 pt-4">
                        <div className="grid grid-cols-2-auto gap-x-10">
                            <span className="font-bold text-xl text-left">Copie:</span>
                            <span>{job.copies}</span>

                            <span className="font-bold text-xl text-left">Costo disegno:</span>
                            <span>€ {job.drawingPrice}</span>
                        </div>
                        <div className="grid grid-cols-2-auto gap-x-10">
                            <span className="font-bold text-xl text-left">Altezza Immagine:</span>
                            <span> {job.height} cm </span>

                            <span className="font-bold text-xl text-left">Larghezza Immagine:</span>
                            <span> {job.width} cm </span>
                        </div>
                        <div className="grid grid-cols-2-auto gap-x-10">
                            <span className="font-bold text-xl text-left">Margine Altezza:</span>
                            <span> {job.marginy || 0} cm </span>

                            <span className="font-bold text-xl text-left">Margine Base:</span>
                            <span> {job.marginx || 0} cm </span>
                        </div>
                    </div>
                </div>)})}
            </div>)
}
