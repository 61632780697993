import {useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Plate} from "@models";
import {PlateService} from "@services/PlateService";
import InfiniteScroll from "react-infinite-scroll-component";
import {PrintSavedJobs} from "@components/PrintSavedJobs";

export function ViewPlate() {
    const [plates, setPlates] = useState<Plate[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [pages, setPages] = useState<number>();
    const [hasMore, setHasMore] = useState<boolean>(true);
    let page = 1;

    const plateId = searchParams.get("id");

    let settings = {
        option: {
            page: page,
            populate: [
                {
                    path: "material",
                    model: "Material"
                },
                {
                    path: "method",
                    model: "Method"
                },
                {
                    path: "thickness",
                    model: "Thickness"
                },
                {
                    path: "jobs",
                    model: "Job",
                    populate: "file customer"
                },
            ]
        },
        query: {_id: plateId.toString()}
    };

    useEffect( () => {
        PlateService.get(settings)
            .then((resp: any) => {
                const plates = resp.data.docs as Plate[];
                setPages(resp.data.totalPages);
                setPlates(plates);
                if (resp.data.totalPages === 1) setHasMore(false);

            })
            .catch((err: any) => {
                console.error(err);
            });
    }, [plateId]);

    const getNextJobs = () =>  {
        if (page >= pages) {
            setHasMore(false);
            return;
        }
        settings.option.page = ++page;
        PlateService.get(settings)
            .then((resp: any) => {
                const plates = resp.data.docs as Plate[];
                setPlates(plates);
            })
            .catch((err: any) => {
                console.error(err);
            });
    }

    return (
        <div>
            <div className="content">
                {
                    plates?.map((plate, index) => {
                        return (
                            <div key={index}>
                                <div className="content mx-10">
                                    <div className="flex items-center justify-between">
                                        <div>
                                            <div
                                                className="title appearance-none bg-transparent mr-3 py-1 leading-tight ">
                                                {plate.name}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-5 flex text-2xl font-bold text-left gap-10 items-center">
                                        <div>{plate.material.name}</div>
                                        -
                                        <div>{plate.method.name}</div>
                                        -
                                        <div>{plate.thickness.name}</div>
                                    </div>

                                    <InfiniteScroll
                                        next={getNextJobs}
                                        hasMore={hasMore}
                                        loader={
                                            <button onClick={getNextJobs} className="mt-5 btn btn-ghost">Carica altri lavori</button>
                                        }
                                        dataLength={10}
                                        scrollableTarget="scrollableDiv"
                                        className="rounded-xl">
                                        <div className="pb-10 h-scroll-2 rounded-xl" id="scrollableDiv">
                                            <PrintSavedJobs
                                                jobs = {plate.jobs}
                                            />
                                        </div>
                                    </InfiniteScroll>
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}
