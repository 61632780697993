import React, {useState} from "react";
import {set, useForm} from "react-hook-form";
import {Customer} from "@models";
import {JobService} from "@services/JobService";
import Select from "react-select";
import {Option} from "react-multi-select-component";

export function AddJob({ customers, parentForm, onSubmit, setAddJobField, getNextCustomers, setCustomerQuery }: any) {

    const [selectedCustomer, setSelectedCustomer] = useState<Option>();
    const [selectedValue, setSelectedValue] = useState("");


    const { register, reset, watch, handleSubmit, setValue, getValues, formState: { errors } } = useForm({
        defaultValues: {
            name: null,
            image: null,
            customerId: null,
            copies: 1,
            drawingPrice: 0
        }
    });

    const handleSubmitJobCallback = (data: any) => {
        data = { ...parentForm, ...data };
        console.log(data);
        const form = new FormData();
        form.append('customerId', selectedCustomer.value);
        form.append('file', data.image[0]);
        form.append('name', data.name);
        form.append('material', data.material);
        form.append('method', data.method);
        form.append('thickness', data.thickness);
        form.append('copies', data.copies);
        form.append('drawingPrice', data.drawingPrice);

        JobService.add(form).then((resp: any) => {
            console.log(resp.data);
            onSubmit(resp.data);
        })
    };


    const onImageUploaded = (event: any) => {
        const img = document.getElementById('imagePreview') as HTMLImageElement | null;
        img.src = URL.createObjectURL(event.target.files[0]);
        img.style.display = 'block';
        document.getElementById('imageUploader').style.display = 'none';
        img.onload = () => {
            URL.revokeObjectURL(img.src) // free memory
        }
        const imageName = (event.target.files[0].name).substring(0,(event.target.files[0].name).indexOf("."));
        if (!getValues('name'))
            setValue('name',imageName, { shouldValidate: true });

        setSelectedValue(customers.filter(((customer: Customer) => customer?.businessName?.replace(/\s/g, "")?.toLowerCase() === imageName?.replace(/\.[^/.]+$/, "")?.toLowerCase()))?.[0]?._id)
    };

    return (
        <form onSubmit={handleSubmit(handleSubmitJobCallback)} encType='multipart/form-data'>
            <div className="mt-10 font-bold flex flex-col justify-between items-center w-full text-left p-10 shadow-md bg-base-100 rounded-lg">
                <label htmlFor="name" className="w-full">
                    <input id="name" name="name"
                           className="title appearance-none bg-transparent py-1 leading-tight focus:outline-none placeholder:underline"
                           type="text" maxLength={100} placeholder="Nome Lavorazione" aria-label="JOb name"
                           {...register("name", { required: true, maxLength: 100 })}
                           />
                    <i className="fa-solid fa-pen fa-2x"></i>
                </label>
                <div className="flex flex-row w-full justify-between mt-5 text-xl">
                    <div className="flex flex-row items-center justify-center w-100">
                        <label htmlFor="image" >
                            <div className="select-none cursor-pointer bg-transparent hover:bg-gray-100 rounded-full bg-white text-black font-semibold hover:text-black py-2 px-6 border-2 border-gray-200 hover:border-transparent">
                                <div id="imageUploader" className="flex flex-row items-center justify-center gap-2">
                                    <i className="fa-solid fa-arrow-up fa-2x m-1"></i> Upload
                                </div>
                                <img style={{display: 'none'}} className="h-10" alt="Preview of SVG uploaded" id="imagePreview"/>
                            </div>

                            <input id="image" type="file" accept="image/svg+xml" className="hidden"
                                   {...register("image", { required: true, onChange: onImageUploaded })}/>
                        </label>
                    </div>
                    <div className="gap-10 mx-10">
                        <div className="grid grid-cols-2-auto gap-10">
                            <div className="col-span-2">
                                <label htmlFor="customerId" className="select-none leading-10">Cliente:</label>
                                <Select
                                    onChange={setSelectedCustomer}
                                    options={customers}
                                    noOptionsMessage={() => {
                                        return  "Nessun cliente trovato"
                                    }}
                                    onMenuScrollToBottom={getNextCustomers}
                                    onInputChange={setCustomerQuery}
                                    isClearable={true}
                                    isSearchable={true}
                                    menuPosition={getNextCustomers}
                                    placeholder="Seleziona un cliente"
                                    value={selectedCustomer}
                                />
                            </div>
                            <div>
                                <label htmlFor="copies" className="select-none leading-10">N° copie:</label>
                                <input id="copies" type="number" min={1}
                                       className="input w-full rounded-md bg-neutral shadow-10 h-10"
                                       {...register("copies", { required: true, min: 1 })}/>
                            </div>
                            <div>
                                <label htmlFor="drawingPrice" className="select-none leading-10">Costo Disegno:</label>
                                <input id="drawingPrice" type="number" min={0}
                                       className="input w-full rounded-md bg-neutral shadow-10 h-10"
                                       {...register("drawingPrice", { required: true, min: 0 })}/>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-evenly items-end gap-2 ">
                        <button className="h-10 w-24 text-white btn btn-warning rounded-full"
                                onClick={() => { setAddJobField(false); reset({
                                    image: null,
                                    customerId: null,
                                    copies: 1,
                                    drawingPrice: 0
                                }); setSelectedCustomer(null) }}>
                            <i className="fa-regular fa-trash-can fa-2x"></i>
                        </button>
                        <button className="h-10 w-24 text-white btn btn-secondary rounded-full" type={"submit"}
                                disabled={!watch('image') || !!!selectedCustomer?.value || !parentForm.material || !parentForm.method || !parentForm.thickness}>
                            <i className="fa-regular fa-circle-check fa-2x"></i>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
}
