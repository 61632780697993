import {useNavigate, useSearchParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {MaterialService} from "@services/MaterialService";
import {Customer, Material, MaterialFactor} from "@models";
import {CustomerService} from "@services/CustomerService";

export function ManageCustomer() {
    const navigate = useNavigate();
    const [materials, setMaterials] = useState<Material[]>(null);
    const [customer, setCustomer] = useState<Customer>(null);
    const { register, reset, handleSubmit, formState: { errors } } = useForm();

    const [searchParams, setSearchParams] = useSearchParams();

    const customerId = searchParams.get("id");
    const [showMaterials, setShowMaterials] = useState<{[key: string]: boolean}>({});
    const [showMethods, setShowMethods] = useState<{[key: string]: boolean}>({});

    const handleSubmitCallback = (data: any) => {
        let materialFactors = [] as MaterialFactor[];
        materials.forEach((material) => {
            material.methods.forEach((method) => {
                method.thicknesses.forEach((thickness) => {
                    materialFactors.push({
                        materialId: material._id,
                        methodId: method._id,
                        thicknessId: thickness._id,
                        value: parseFloat(data[`materialFactors`]?.[material._id]?.[method._id]?.[thickness._id]?.value) || 0,
                        marginx: parseFloat(data[`materialFactors`]?.[material._id]?.[method._id]?.[thickness._id]?.marginx) || 2,
                        marginy: parseFloat(data[`materialFactors`]?.[material._id]?.[method._id]?.[thickness._id]?.marginy) || 2,
                        minArea: parseFloat(data[`materialFactors`]?.[material._id]?.[method._id]?.[thickness._id]?.minArea) || 100,
                    } as MaterialFactor);
                });
            });
        });

        const customer: Customer = {...data, materialFactors};

        if (!!customerId) {
            CustomerService.update(customer, customerId).then((resp: any) => {
                navigate('/customers');
            }).catch((err: any) => console.error(err));
        }
        else {
            CustomerService.add(customer).then((resp: any) => {
                navigate('/customers');
            }).catch((err: any) => console.error(err));

        }
    };

    useEffect( () => {
        if(!!customerId){
            const query = {_id: customerId.toString()};
            CustomerService.get(query)
                .then((resp: any) =>
                    setCustomer(resp.data.docs[0])
                ).catch((err: any) => {
                        console.error(err);
                });
        }
        MaterialService.getAll()
            .then((resp: any) => setMaterials(resp.data))
            .catch((err: any) => {
                console.error(err);
            });
    }, [setMaterials, setCustomer]);

    useEffect( () => {
        let materialFactors = [] as any;
        customer?.materialFactors.forEach((factor: MaterialFactor) => {
            if (!materialFactors[factor.materialId])
                materialFactors[factor.materialId] = {};
            if (!materialFactors[factor.materialId][factor.methodId])
                materialFactors[factor.materialId][factor.methodId] = {};
            materialFactors[factor.materialId][factor.methodId][factor.thicknessId] = {
                value: factor.value,
                marginx: factor.marginx,
                marginy: factor.marginy,
                minArea: factor.minArea
            };
        });
        reset({...customer, materialFactors});
    }, [customer])

    return (
        <div>
            <div className="content mx-10">
                <form onSubmit={handleSubmit(handleSubmitCallback)}>
                    <div className="flex items-center justify-between">
                        <div>
                            <label htmlFor="customerCode">
                                <input id="customerCode" name="customerCode"
                                       className="title appearance-none bg-transparent text-gray-700 mr-3 py-1 leading-tight focus:outline-none placeholder:underline"
                                       type="text"  maxLength={100} placeholder="Codice Cliente *" aria-label="Customer Code"
                                       {...register("customerCode", { required: true, maxLength: 100 })}/>
                                <i className="fa-solid fa-pen fa-2x"></i>
                            </label>
                            <span className="text-error title-margin label-text mt-1">{errors.customerCode && "* Campo obbligatorio"}</span>
                        </div>
                        <button type="submit" className="btn-secondary text-white flex items-center justify-around btn btn-lg btn-primary rounded-full gap-2 mr-10">
                            <i className="fa-solid fa-user-check"></i> Salva
                        </button>
                    </div>

                    <div className="mt-5 grid grid-cols-2 gap-10">
                        <div className="w-full">
                            <div className="flex items-center justify-between">
                                <label className="label font-bold ml-4" htmlFor="businessName">
                                    <span className="label-text">Ragione Sociale *</span>
                                </label>
                                <span className="text-error title-margin label-text mt-1">{errors.businessName && "* Campo obbligatorio"}</span>
                            </div>
                            <input
                                id="businessName" type="text" maxLength={100}
                                className="input input-md w-full rounded-full input-field shadow-10"
                                {...register("businessName", { required: true, maxLength: 100 })}/>
                        </div>
                        <div className="w-full">
                            <div className="flex items-center justify-between">
                                <label className="label font-bold ml-4" htmlFor="vat">
                                    <span className="label-text">Partita IVA</span>
                                </label>
                                <span className="text-error title-margin label-text mt-1">{errors.vat && "Campo obbligatorio"}</span>
                            </div>
                            <input
                                id="vat" type="text" maxLength={100}
                                className="input input-md w-full rounded-full input-field shadow-10"
                                {...register("vat", { required: false, maxLength: 100 })}/>
                        </div>
                        <div className="w-full">
                            <div className="flex items-center justify-between">
                                <label className="label font-bold ml-4" htmlFor="city">
                                    <span className="label-text">Città</span>
                                </label>
                                <span className="text-error title-margin label-text mt-1">{errors.city && "Campo obbligatorio"}</span>
                            </div>
                            <input
                                id="city" type="text" maxLength={100}
                                className="input input-md w-full rounded-full input-field shadow-10"
                                {...register("city", { required: false, maxLength: 100 })}/>
                        </div>
                        <div className="w-full">
                            <div className="flex items-center justify-between">
                                <label className="label font-bold ml-4" htmlFor="zipCode">
                                    <span className="label-text">CAP</span>
                                </label>
                                <span className="text-error title-margin label-text mt-1">{errors.zipCode && "Campo obbligatorio"}</span>
                            </div>
                            <input
                                id="zipCode" type="text" maxLength={100}
                                className="input input-md w-full rounded-full input-field shadow-10"
                                {...register("zipCode", { required: false, maxLength: 100 })}/>
                        </div>
                        <div className="w-full">
                            <div className="flex items-center justify-between">
                                <label className="label font-bold ml-4" htmlFor="address">
                                    <span className="label-text">Indirizzo</span>
                                </label>
                                <span className="text-error title-margin label-text mt-1">{errors.address && "Campo obbligatorio"}</span>
                            </div>
                            <input
                                id="address" type="text" maxLength={100}
                                   className="input input-md w-full rounded-full input-field shadow-10"
                                   {...register("address", { required: false, maxLength: 100 })}/>
                        </div>
                        <div className="w-full">
                            <div className="flex items-center justify-between">
                                <label className="label font-bold ml-4" htmlFor="phoneNumber">
                                    <span className="label-text">Numero di telefono</span>
                                </label>
                                <span className="text-error title-margin label-text mt-1">{errors.phoneNumber && "Campo obbligatorio"}</span>
                            </div>
                            <input
                                id="phoneNumber" type="text" maxLength={100}
                                className="input input-md w-full rounded-full input-field shadow-10"
                                {...register("phoneNumber", { required: false, maxLength: 100 })}/>
                        </div>
                        <div className="w-full">
                            <div className="flex items-center justify-between">
                                <label className="label font-bold ml-4" htmlFor="email">
                                    <span className="label-text">Email</span>
                                </label>
                                <span className="text-error title-margin label-text mt-1">{errors.email && "Campo obbligatorio"}</span>
                            </div>
                            <input
                                id="email" type="email" maxLength={100}
                                className="input input-md w-full rounded-full input-field shadow-10"
                                {...register("email", { required: false, maxLength: 100 })}/>
                        </div>
                        <div className="w-full">
                            <div className="flex items-center justify-between">
                                <label className="label font-bold ml-4" htmlFor="pec">
                                    <span className="label-text">PEC</span>
                                </label>
                                <span className="text-error title-margin label-text mt-1">{errors.pec && "Campo obbligatorio"}</span>
                            </div>
                            <input
                                id="pec" type="email" maxLength={100}
                                className="input input-md w-full rounded-full input-field shadow-10"
                                {...register("pec", { required: false, maxLength: 100 })}/>
                        </div>
                    </div>

                    <div className="mt-5 text-2xl font-bold title-margin text-left">Coefficienti</div>

                    <div className="mt-1 mb-10">
                        <div>
                            <div className="flex text-sm font-bold py-3 gap-2" role="alert">
                                <i className="fa-solid fa-circle-info text-secondary"></i>
                                <p className="select-none text-justify font-normal">
                                    I costi vengono calcolati moltiplicando tra loro i coefficienti che dipendono dal materiale,
                                    dalle dimensioni delle lavorazioni e dallo spessore della lastra.
                                </p>
                            </div>
                        </div>
                        {
                            materials?.map((material) => {
                                return (
                                    <div key={`${material._id}`} className="shadow-md bg-base-100 my-5 rounded-2xl">
                                        <button type="button" className="shadow-md bg-base-100 p-3 w-full rounded-2xl"
                                                onClick={() => {
                                                    setShowMaterials({
                                                        ...showMaterials,
                                                        [material._id]: !showMaterials[material._id]
                                                    });
                                                } }>
                                            <div className="flex justify-between items-center text-2xl text-left font-bold">
                                                <span>{material.name}</span>
                                                <i className={`fa-solid fa-angle-down fa-2x transition-all duration-300 ${showMaterials[material._id] ? "rotate-180" : ""}`} id={`caret-${material._id}`}></i>
                                            </div>
                                        </button>
                                        { showMaterials[material._id] &&
                                            <div className="mx-4">
                                                {
                                                    material.methods?.map((method) => {
                                                        return (
                                                            <div key={`${method._id}`} className="bg-white mb-5 rounded-2xl">
                                                                <button type="button" className="bg-white p-3 w-full rounded-2xl"
                                                                        onClick={() => {
                                                                            setShowMethods({
                                                                                ...showMethods,
                                                                                [material._id + method._id]: !showMethods[material._id + method._id]
                                                                            });
                                                                        } }>
                                                                    <div className="flex justify-between items-center text-2xl text-left font-bold">
                                                                        <span>{method.name}</span>
                                                                        <i className={`fa-solid fa-angle-down fa-2x transition-all duration-300 ${showMethods[material._id + method._id] ? "rotate-180" : ""}`}></i>
                                                                    </div>
                                                                </button>
                                                                <div>
                                                                    { showMethods[material._id + method._id] &&
                                                                        method.thicknesses?.map((thickness) => {
                                                                            return (
                                                                                <div key={`${thickness._id}`}>
                                                                                    <div className="px-3 flex items-center justify-between gap-5 w-full">
                                                                                        <span className="font-bold text-xl min-w-fit">
                                                                                            {thickness.name}
                                                                                        </span>
                                                                                        <hr className="w-full border-y-1 rounded-lg border-current"/>
                                                                                    </div>
                                                                                    <div className="flex items-center justify-between gap-10 mx-4">
                                                                                        <div className="w-full">
                                                                                            <label className="label font-bold"
                                                                                                   htmlFor={`value[${material._id}][${method._id}][${thickness._id}]`}>
                                                                                            <span className="label-text">
                                                                                                Valore:
                                                                                            </span>
                                                                                            </label>
                                                                                            <div className="relative">
                                                                                                <input
                                                                                                    id={`value[${material._id}][${method._id}][${thickness._id}]`}
                                                                                                    className="input input-md w-full rounded-full input-field shadow-10"
                                                                                                    type="number" min={0} step={0.001}
                                                                                                    {...register(`materialFactors[${material._id}][${method._id}][${thickness._id}].value`, {
                                                                                                        required: false,
                                                                                                        validate: value => value >= 0
                                                                                                    })}/>
                                                                                                <div className="flex absolute inset-y-0 right-14 items-center pl-3 pointer-events-none">
                                                                                                    <span className="select-none font-bold">€/cmq</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <span className="text-error title-margin label-text mt-1">{errors[`materialFactors[${material._id}][${method._id}][${thickness._id}].value`] && "Campo obbligatorio"}</span>
                                                                                        </div>
                                                                                        <div className="w-full">
                                                                                            <label className="label font-bold"
                                                                                                   htmlFor={`marginy[${material._id}][${method._id}][${thickness._id}]`}>
                                                                                                <span className="label-text">
                                                                                                    Margine Altezza:
                                                                                                </span>
                                                                                            </label>
                                                                                            <div className="relative">
                                                                                                <input
                                                                                                    id={`marginy[${material._id}][${method._id}][${thickness._id}]`}
                                                                                                    className="input input-md w-full rounded-full input-field shadow-10"
                                                                                                    type="number" min={0} step={0.1}
                                                                                                    {...register(`materialFactors[${material._id}][${method._id}][${thickness._id}].marginy`, {
                                                                                                        required: false,
                                                                                                        validate: value => value >= 0
                                                                                                    })}/>
                                                                                                <div className="flex absolute inset-y-0 right-14 items-center pl-3 pointer-events-none">
                                                                                                    <span className="select-none font-bold">cm</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <span className="text-error title-margin label-text mt-1">{errors[`materialFactors[${material._id}][${method._id}][${thickness._id}].marginy`] && "Campo obbligatorio"}</span>
                                                                                        </div>
                                                                                        <div className="w-full">
                                                                                            <label className="label font-bold"
                                                                                                   htmlFor={`marginx[${material._id}][${method._id}][${thickness._id}]`}>
                                                                                            <span className="label-text">
                                                                                                Margine Base:
                                                                                            </span>
                                                                                            </label>
                                                                                            <div className="relative">
                                                                                                <input
                                                                                                    id={`marginx[${material._id}][${method._id}][${thickness._id}]`}
                                                                                                    className="input input-md w-full rounded-full input-field shadow-10"
                                                                                                    type="number" min={0} step={0.1}
                                                                                                    {...register(`materialFactors[${material._id}][${method._id}][${thickness._id}].marginx`, {
                                                                                                        required: false,
                                                                                                        validate: value => value >= 0
                                                                                                    })}/>
                                                                                                <div className="flex absolute inset-y-0 right-14 items-center pl-3 pointer-events-none">
                                                                                                    <span className="select-none font-bold">cm</span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <span className="text-error title-margin label-text mt-1">{errors[`materialFactors[${material._id}][${method._id}][${thickness._id}].marginx`] && "Campo obbligatorio"}</span>
                                                                                        </div>
                                                                                        <div className="w-full">
                                                                                            <label className="label font-bold"
                                                                                                   htmlFor={`minArea[${material._id}][${method._id}][${thickness._id}]`}>
                                                                                            <span className="label-text">
                                                                                                Superficie Minima:
                                                                                            </span>
                                                                                            </label>
                                                                                            <div className="relative">
                                                                                                <input
                                                                                                    id={`minArea[${material._id}][${method._id}][${thickness._id}]`}
                                                                                                    className="input input-md w-full rounded-full input-field shadow-10"
                                                                                                    type="number" min={0}
                                                                                                    {...register(`materialFactors[${material._id}][${method._id}][${thickness._id}].minArea`, {
                                                                                                        required: false,
                                                                                                        validate: value => value >= 0
                                                                                                    })}/>
                                                                                                <div className="flex absolute inset-y-0 right-14 items-center pl-3 pointer-events-none">
                                                                                                    <span className="select-none font-bold">/cm<sup>2</sup> </span>
                                                                                                </div>
                                                                                            </div>

                                                                                            <span className="text-error title-margin label-text mt-1">{errors[`materialFactors[${material._id}][${method._id}][${thickness._id}].margin`] && "Campo obbligatorio"}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>);
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }
                                                <div className="h-1"></div>
                                            </div>
                                        }

                                    </div>
                                );
                            })
                        }
                    </div>
                </form>
            </div>
        </div>
    );
}
