import {ParentJob} from "../../models/ParentJob";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import * as pdfInterfaces from 'pdfmake/interfaces';

(<any>pdfMake).vfs = pdfFonts.pdfMake.vfs;

export function ExportPDF (exportingJobs : ParentJob[], ddtNumber : number, customerName : string, hasMargin : boolean) : pdfInterfaces.TDocumentDefinitions {

    const customerJob: any = [];
    let index = 1;

    exportingJobs.map(job => {
        customerJob.push(
            hasMargin ?
                [
            {text : job.fatherJob.copies, alignment: "center", margin : [0,2,0,2]},
            {text : job.fatherJob.name, alignment: "center", margin : [0,2,0,2]},
            {table : {
                    widths : ["*","*"],
                    body  : [
                        [
                            {text : (job.fatherJob.marginx + job.fatherJob.width), border: [0,0,1,0], alignment: "center"},
                            {text : (job.fatherJob.marginy + job.fatherJob.height), border: [0,0,0,0], alignment: "center"},
                        ]
                    ],
                    margin : [0,2,0,2]
                }},
            {text : job.fatherJob.width, alignment: "center", margin : [0,2,0,2]},
            {text : job.fatherJob.height, alignment: "center", margin : [0,2,0,2]},
            {text : job.fatherJob.drawingPrice, alignment: "center", margin : [0,2,0,2]},
            {text : job.fatherJob.price, alignment: "center", margin : [0,2,0,2]}
        ] :
                [
                    {text : job.fatherJob.copies, alignment: "center", margin : [0,2,0,2]},
                    {text : job.fatherJob.name, alignment: "center", margin : [0,2,0,2]},
                    {text : job.fatherJob.width, alignment: "center", margin : [0,2,0,2]},
                    {text : job.fatherJob.height, alignment: "center", margin : [0,2,0,2]},
                    {text : job.fatherJob.drawingPrice, alignment: "center", margin : [0,2,0,2]},
                    {text : job.fatherJob.price, alignment: "center", margin : [0,2,0,2]}
                ]
        )
        index++
        if (!!job.childrenJob) {
            customerJob.push(
                hasMargin ?
                    [
                {text : job.childrenJob.copies, alignment: "center", margin : [0,2,0,2]},
                {text : job.childrenJob.name, alignment: "center", margin : [0,2,0,2]},
                {table : {
                        widths : ["*","*"],
                        body  : [
                            [
                                {text : (job.childrenJob.marginx + job.childrenJob.width), border: [0,0,1,0], alignment: "center"},
                                {text : (job.childrenJob.marginy + job.childrenJob.height), border: [0,0,0,0], alignment: "center"},
                            ]
                        ],
                        margin : [0,2,0,2]
                    }},
                {text : job.childrenJob.width, alignment: "center", margin : [0,2,0,2]},
                {text : job.childrenJob.height, alignment: "center", margin : [0,2,0,2]},
                {text : job.childrenJob.drawingPrice, alignment: "center", margin : [0,2,0,2]},
                {text : 0, alignment: "center", margin : [0,2,0,2]}
                ] :
                    [
                    {text : job.childrenJob.copies, alignment: "center", margin : [0,2,0,2]},
                    {text : job.childrenJob.name, alignment: "center", margin : [0,2,0,2]},
                    {text : job.childrenJob.width, alignment: "center", margin : [0,2,0,2]},
                    {text : job.childrenJob.height, alignment: "center", margin : [0,2,0,2]},
                    {text : job.childrenJob.drawingPrice, alignment: "center", margin : [0,2,0,2]},
                    {text : 0, alignment: "center", margin : [0,2,0,2]}
                ]

            )
            index++
        }
    })

    return {
        pageSize: 'A4',
        pageMargins: [40, 40, 40, 160],
        footer: function (currentPage : number, pageCount : number) {
            if (currentPage == pageCount)
                return {
                    table: {
                        widths: ['*'],
                        height : 100,
                        body: [
                            [{
                                alignment: 'left',
                                margin : [20,0,20,20],
                                text: [
                                    {text: "CONDIZIONI DI VENDITA\n",bold: true},
                                    {text: "1) IL CLIENTE E' TENUTO A CONTROLLARE GLI IMPIANTI STAMPA CONSEGNATI (CLICHES, FOTOPOLIMERI, PELLICOLE) "},
                                    {text: "IN OGNI PARTICOLARE PRIMA E DURANTE L'UTILIZZO.\n"},
                                    {text: "2) IN CASO DI ERRORI NON SARA' NOSTRA RESPONSABILITA' RISPONDERE DEGLI EVENTUALI DANNI CAUSATI DA UN"},
                                    {text: "MANCATO CONTROLLO. SI SUGGERISCE PER QUESTO DI CONTROLLARE ANCHE LE PRIME STAMPE AL FINE D "},
                                    {text: "VERIFICARE CHE TUTTO SIA CONFORME A QUANTO RICHIESTO IN FASE DI ORDINE.\n"},
                                    {text: "3) IL COSTO DEL DISEGNO COSTITUISCE UN SEMPLICE CONTRIBUTO E NON ATTRIBUISCE LA PROPRIETA' DEL FILE.\n"},
                                    {text : "ATTENZIONE ", bold : true},
                                    {text : "CONSERVARE IL PRESENTE ALLEGATO CON LA FATTURA "}
                                ]
                            }]
                        ]
                    },
                    layout: 'noBorders'
                };
        },
        content: [
            {
                table: {
                    widths: ["*", "*"],
                    body: [
                        [
                            {
                                margin: [0, 10, 0, 10],
                                italics : true,
                                border : [1,1,1,0],
                                alignment : "center",
                                text : [
                                    {text: "LA FOTOMECCANICA\n",bold: true},
                                    {text: "DI COLUMBO LUIGI\n"},
                                    {text: "VIA DEGLI ARREDATORI, 15\n"},
                                    {text: "TEL: 080-5367292\n"},
                                    {text: "E-MAIL: "},
                                    {text: "columbo@lafotomeccanica.it\n",bold: true},
                                    {text: "INTERNET:"},
                                    {text: "www.lafotomeccanica.it",bold: true},
                                ]
                            },
                            {
                                margin: [0, 5, 0, 5],
                                border : [1,1,1,0],
                                italics : true,
                                alignment : "center",
                                text : [
                                    {text: "BUONO DI CONSEGNA\n"},
                                    {text: "CLICHES PER STAMPA A CALDO\n"},
                                    {text: "VIA DEGLI ARREDATORI, 15\n"},
                                    {text: "STAMPA A RILIEVO\n"},
                                    {text: "OLOGRAMMI - FLEXOGRAFIA\n"},
                                    {text: "CLICHES IN OTTONE\n",bold: true},
                                    {text: "TRIDIMENSIONALI",bold: true},
                                ]
                            }
                        ],
                    ]
                }
            },{
                table: {
                    widths: ["*", 200],
                    body: [
                        ["", {
                            alignment : "center",

                            text : [
                                {text: `DDT N° ${ddtNumber} DEL ${format(new Date())}`},
                            ]
                        }
                        ],
                    ]
                }
            },{
                table: {
                    widths : '*',
                    body: [
                        [{
                            alignment : "center",
                            border : [1,0,1,1],
                            text : [
                                {text: `destinatorio merci`, bold : true},
                                {text: ` : ${customerName}`},
                            ]
                        }
                        ],
                    ]
                }
            },
            {
                margin : [0,10,0,0],
                table: {
                    widths: hasMargin ? [30,100,100,60,60,60,"*"] : [30,100,120,80,80,"*"],
                    body: [
                        hasMargin ? [
                            {text : "N°", alignment: "center", margin : [0,2,0,2]},
                            {text : "NOME LAVORO", alignment: "center", margin : [0,2,0,2]},
                            {text : "DIMM. GRAFICA", alignment: "center", margin : [0,2,0,2]},
                            {text : "BASE", alignment: "center", margin : [0,2,0,2]},
                            {text : "ALTEZZA", alignment: "center", margin : [0,2,0,2]},
                            {text : "DISEGNO", alignment: "center", margin : [0,2,0,2]},
                            {text : "COSTO", alignment: "center", margin : [0,2,0,2]},
                        ] :
                            [
                                {text : "N°", alignment: "center", margin : [0,2,0,2]},
                                {text : "NOME LAVORO", alignment: "center", margin : [0,2,0,2]},
                                {text : "BASE", alignment: "center", margin : [0,2,0,2]},
                                {text : "ALTEZZA", alignment: "center", margin : [0,2,0,2]},
                                {text : "DISEGNO", alignment: "center", margin : [0,2,0,2]},
                                {text : "COSTO", alignment: "center", margin : [0,2,0,2]},
                            ],
                        ...customerJob,
                    ]
                }
            },
            {
                margin : [0,20,0,0],
                table: {
                    widths: [30,100,120,80,80,"*"],
                    body: [
                        [
                            {text : "", alignment: "center", border : [0,0,0,0]},
                            {text : "", alignment: "center", border : [0,0,0,0]},
                            {text : "", alignment: "center", border : [0,0,0,0]},
                            {text : "", alignment: "center", border : [0,0,0,0]},
                            {text : "", alignment: "center", border : [0,0,0,0]},
                            {text : "TOTALE", alignment: "center", border : [0,0,0,0], italics : true, bold : true},
                        ],[
                            {text : customerJob?.length, alignment: "center", border : [1,1,1,1]},
                            {text : "", alignment: "center", border : [0,0,0,1]},
                            {text : "", alignment: "center", border : [0,0,0,1]},
                            {text : "", alignment: "center", border : [0,0,0,1]},
                            {text : "", alignment: "center", border : [0,0,0,1]},
                            {text : exportingJobs.reduce(((a, b) => a + b.fatherJob.price),0), alignment: "center", border : [1,1,1,1], italics : true, bold : true},
                        ]
                    ]
                }
            }
        ]
    }
}

function format(inputDate : Date) {
    const date = inputDate.getDate().toString();
    const month = (inputDate.getMonth() + 1).toString();
    const year = inputDate.getFullYear();
    return `${date}/${month}/${year}`;
}

