import http from "./axios";
import {User} from "@models";

const {REACT_APP_SERVER_URL} = process.env;

export const UserService = {
    me: () => {
        return http.get<User>(`${REACT_APP_SERVER_URL}/auth/me`, {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }
        });
    },
    auth: (username: string, password: string) => {
        return http.post<{token: string}>(`${REACT_APP_SERVER_URL}/auth/login`, {username, password});
    },
    // getPost: (id) => {
    //     return http.get(`${REACT_APP_SERVER_URL}/api/post/get?id=${id}`, {
    //         headers: {
    //             "Authorization": localStorage.getItem("auth") ?? ""
    //         }
    //     });
    // },
    // submitComment: (data) => {
    //     return http.post(`${REACT_APP_SERVER_URL}/api/comment/submit`, data, {
    //         headers: {
    //             "Authorization": localStorage.getItem("auth") ?? ""
    //         }
    //     });
    // },
};
