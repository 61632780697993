import React, {KeyboardEventHandler, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import Select from "react-select";
import {MultiSelect, Option} from "react-multi-select-component";
import {MethodService} from "@services/MethodService";
import {Material, Method, Thickness} from "@models";
import {ThicknessService} from "@services/ThicknessService";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {log} from "util";
import {MaterialService} from "@services/MaterialService";
import {CustomerService} from "@services/CustomerService";
export function ManageMaterials () {

    const navigate = useNavigate();
    const { register, reset, handleSubmit, formState: { errors } } = useForm();
    const [searchParams, setSearchParams] = useSearchParams();
    const materialId = searchParams.get("id");

    const [thicknesses, setThicknesses] = useState<Option[]>([]);
    const [thicknessSelected, setSelectedThickness] = useState<Option[]>([]);
    const [methodSelected, setSelectedMethod] = useState<Option>();
    const [methods, setMethods] = useState<Option[]>([]);
    const [optionMethods, setOptionMethods] = useState<Option[]>([]);
    const [thicknessesMethod, setThicknessesMethod] = useState<Method[]>([]);
    const [fullyMethods, setFullyMethods] = useState<Method[]>([]);

    const [isCreatingMethod, setIsCreatingMethod] = useState<boolean>(false);
    const [isEditingMethod, setisEditingMethod] = useState<boolean>(false);
    const [editingMethod, setEditingMethod] = useState<Method>()
    const [deletingMethod, setDeletingMethod] = useState<Method>()
    const [openDeleteMethod, setOpenDeleteMethod] = useState(false);
    const [isThicknessDisabled, setIsThicknessDisabled] = useState(false);
    const [inputMethodValue, setInputMethodValue] = useState("")
    const [inputThicknessValue, setInputThicknessValue] = useState("")
    const [nameMaterial, setNameMaterial] = useState("")

    const handleKeyDownMethod: KeyboardEventHandler = (event) => {
        if (!inputMethodValue) return;
        switch (event.key) {
            case 'Enter':
                event.preventDefault();
                const method = {
                    name : inputMethodValue,
                    thicknesses : []
                } as Method
                MethodService.add(method).then(resp => {
                    if (resp.status === 200){
                        const m = resp.data as Method;
                        setMethods([...methods, {value: m._id, label: `${m.name}`}]);
                        setOptionMethods([...optionMethods, {value: m._id, label: `${m.name}`}]);
                        setSelectedMethod({value: m._id, label: `${m.name}`})
                        setInputMethodValue("")
                        setSelectedThickness([])
                        setIsThicknessDisabled(false)
                    }
                })
        }
    };

    const handleKeyDownThickness: KeyboardEventHandler = (event) => {
        if (!inputThicknessValue) return;
        switch (event.key) {
            case 'Enter':
                event.preventDefault();
                const thickness = {
                    name : inputThicknessValue
                } as Thickness
                ThicknessService.add(thickness).then(resp => {
                    if (resp.status === 200){
                        const t = resp.data as Thickness;
                        setThicknesses([...thicknesses, {value: t._id, label: `${t.name}`}]);
                        setInputThicknessValue("")
                    }
                })
        }
    };

    function getMethods() {
        MethodService.getAll().then(resp => {
            const methodOptions : Option[] = [];
            const methods = resp.data as Method[];
            setFullyMethods(methods);
            methods.forEach(method => {
                methodOptions.push({value: method._id, label: `${method.name}`})
            })
            setMethods(methodOptions);
            setOptionMethods(methodOptions);
        })
    }

    function getThicknesses() {
        ThicknessService.getAll().then(resp => {
            const thicknessOptions : Option[] = [];
            const thicknesses = resp.data as Thickness[];
            thicknesses.forEach(thickness => {
                thicknessOptions.push({value: thickness._id, label: `${thickness.name}`});
            })
            setThicknesses(thicknessOptions);
        })
    }

    function updateOptionMetods () {
        setOptionMethods(methods.filter(method => !thicknessesMethod.some(m => m._id === method.value)))
    }


    useState(() => {
        getMethods();
        getThicknesses();
        if (!!materialId) {
            MaterialService.get({query : {_id : materialId,isDeleted: false}, option : {
                    populate: [
                        {
                            path: "methods",
                            model: "Method",
                            populate: "thicknesses"
                        },
                    ]}
            }).then((resp: any) =>{
                    const material = resp.data.docs[0] as Material;
                    setNameMaterial(material.name)
                    setThicknessesMethod(material.methods)
                    setIsThicknessDisabled(true)
                }).catch((err: any) => {
                console.error(err);
            });
        }
    })

    const handleSubmitCallback = (data : any) => {
        const material = {
            name : data?.name,
            methods : thicknessesMethod
        } as Material;
        if (!!materialId) {
            MaterialService.update(material, materialId).then((resp: any) => {
                if (resp.status === 200) {
                    navigate('/materials');
                }
            }).catch((err: any) => console.error(err));
        }
        else {
            MaterialService.add(material).then(resp => {
                if (resp.status === 200) {
                    navigate('/materials');
                }
            }).catch((err: any) => console.error(err));
        }
    }

    const handleEditMethod = (idMethod : string) => {
        setisEditingMethod(true);
        setEditingMethod(thicknessesMethod.find(method => method._id === idMethod))
        setSelectedMethod(methods.find(m => m.value === idMethod))
        const methodSelected = thicknessesMethod.find(thicknessMethod => thicknessMethod._id === idMethod)
        methodSelected.thicknesses.map(thicknessS => {
            thicknessSelected.push(thicknesses.find(thickness => thickness.value === thicknessS._id))
            setSelectedThickness(thicknessSelected)
        })
        updateOptionMetods()
    }




    const handleSumbitMethod = () => {
        const thicknesses : Thickness[] = [];
        thicknessSelected.map(thickness => {
            thicknesses.push({
                _id : thickness.value,
                name : thickness.label
            })
        });

        const method : Method = {
            name : methodSelected.label,
            _id : methodSelected.value,
            thicknesses
        };

        MethodService.update(method, method._id).then(resp => {
            if (resp.status === 200) {
                setThicknessesMethod(isEditingMethod ? [...thicknessesMethod.filter(m => m._id !== editingMethod._id), method] : [...thicknessesMethod, method]);
                setIsCreatingMethod(false);
                setisEditingMethod(false);
                setSelectedMethod(null);
                setSelectedThickness([]);
            }
        })
    }

    const handleDeleteMethod = () => {
        setThicknessesMethod([...thicknessesMethod.filter(m => m._id !== deletingMethod._id)]);
        setOpenDeleteMethod(false)
        updateOptionMetods()
    }

    const handleOpenModalDeleteMethod = (idMethod : string) => {
        setOpenDeleteMethod(true)
        setDeletingMethod(thicknessesMethod.find(method => method._id === idMethod));
    }

    const handleCloseModalDeleteMethod = () => {
        setOpenDeleteMethod(false)
    }

    const handleSelectMethod = (prevState : any) => {
        setSelectedMethod(prevState)
        const selectedThicknesses = thicknesses?.filter(thickness => {
            const m = fullyMethods?.find(fullyMethod => fullyMethod._id === prevState.value)
            if (!!m.thicknesses) {
                return m.thicknesses.find(t => t === thickness.value);
            } else {
                return []
            }
        });

        if (selectedThicknesses.length !== 0){
            setIsThicknessDisabled(true)
        } else {
            setIsThicknessDisabled(false)
        }
        setSelectedThickness(selectedThicknesses)
    }

    return (
        <div>
            <Dialog
                open={openDeleteMethod}
                onClose={handleCloseModalDeleteMethod}
                style={{
                    borderRadius: 2
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    Sicuro di voler eliminare?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Questa operazione eliminerà il metodo dalla lista.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseModalDeleteMethod} className="flex items-center justify-around btn bg-transparent hover:bg-transparent rounded-full text-secondary border-0 py-2 px-4">
                        Annulla
                    </button>
                    <button onClick={handleDeleteMethod} className="flex items-center justify-around btn btn-error rounded-full bg-warning text-white border-0 py-2 px-4">
                        Elimina
                    </button>
                </DialogActions>
            </Dialog>
            <div className="content mx-10">
                <form onSubmit={handleSubmit(handleSubmitCallback)}>
                        <div className="flex items-center justify-between">
                            <div>
                                <label htmlFor="name">
                                    <input id="name" name="name"
                                           className="title appearance-none bg-transparent text-gray-700 mr-1 py-1 leading-tight focus:outline-none placeholder:underline"
                                           type="text"  maxLength={100} placeholder="Nome Materiale *" aria-label="Material Name" defaultValue={nameMaterial}
                                           {...register("name", { required: true, maxLength: 100 })}/>
                                    <i className="fa-solid fa-pen fa-2x"></i>
                                </label>
                                <span className="text-error title-margin label-text mt-1">{errors.materialName && "* Campo obbligatorio"}</span>
                            </div>
                            <button type="submit" disabled={thicknessesMethod.length === 0} className="btn-secondary text-white flex items-center justify-around btn btn-lg btn-primary rounded-full gap-2 mr-10">
                                <i className="fa-solid fa-user-check"></i> Salva
                            </button>
                        </div>

                        <div className="w-full my-3">
                            <div className="flex-row justify-center w-full">
                                {isCreatingMethod || isEditingMethod ? (
                                    <div className="flex justify-between std-radius bg-base-200 p-5">
                                        <div className="w-full">
                                            <div className="flex justify-between items-center">
                                                <div className="w-full">
                                                    <span className="font-bold text-2xl flex justify-start mt-2">Metodo</span>
                                                    <Select
                                                        options={optionMethods}
                                                        value={methodSelected}
                                                        className="rounded-full text-black w-1/3"
                                                        placeholder="Nome Metodo"
                                                        onChange={(prevState) => handleSelectMethod(prevState)}
                                                        onInputChange={(evt) => setInputMethodValue(evt)}
                                                        inputValue={inputMethodValue}
                                                        onKeyDown={handleKeyDownMethod}
                                                        isClearable={true}
                                                        isSearchable={true}
                                                        noOptionsMessage={() => "Premi Enter per aggiungere il metodo " + inputMethodValue}
                                                    />
                                                </div>
                                                <button type="button" onClick={handleSumbitMethod}  className="btn-secondary text-white flex items-center justify-around btn btn-lg btn-primary rounded-full gap-2">
                                                    <i className="fa-solid fa-check"></i> {isEditingMethod ? "Modifica" : "Salva"}
                                                </button>
                                            </div>
                                            <span className="font-bold text-2xl flex justify-start mt-2">Spessori</span>
                                            <Select
                                                isMulti
                                                options={thicknesses}
                                                value={thicknessSelected}
                                                placeholder="Nome Spessore"
                                                onChange={(newValue) => setSelectedThickness([...newValue])}
                                                isDisabled={isThicknessDisabled}
                                                onInputChange={(evt) => setInputThicknessValue(evt)}
                                                inputValue={inputThicknessValue}
                                                onKeyDown={handleKeyDownThickness}
                                                isClearable={true}
                                                isSearchable={true}
                                                className="rounded-full text-black w-full"
                                                noOptionsMessage={() => "Premi Enter per aggiungere lo spessore " + inputThicknessValue}

                                            />
                                        </div>
                                    </div>) : thicknessesMethod.length === 0 ? ((
                                    <div className="std-radius bg-base-200 p-5">
                                        <div className="font-bold text-3xl">Aggiungi Metodo</div>
                                        <i className="fa-solid fa-plus-circle fa-3x p-2 text-secondary cursor-pointer" onClick={() => {
                                            updateOptionMetods()
                                            setIsCreatingMethod(true)
                                        }}></i>
                                    </div>
                                )) : (<div>
                                    {thicknessesMethod.map(t => {
                                        return (
                                            <div className="std-radius bg-base-200 p-5 my-3 flex justify-between items-center">
                                                <div className="font-bold text-3xl">{t.name}</div>
                                                <div className="flex">
                                                    <button type="button" onClick={() => handleEditMethod(t._id)} className="mx-3 h-10 w-30 btn btn-lg border-none flex items-center justify-around rounded-full btn-secondary text-white">
                                                        <div className="m-1 text-xl"  >
                                                            <i className="fa-solid fa-pencil"></i>
                                                        </div>
                                                    </button>
                                                    <button type="button" onClick={() => handleOpenModalDeleteMethod(t._id)} className="h-10 w-30 btn btn-lg border-none flex items-center justify-around rounded-full btn-warning text-white">
                                                        <div className="m-1 text-xl">
                                                            <i className="fa-solid fa-trash-can" ></i>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>)
                                }
                            </div>
                            {thicknessesMethod.length > 0 && !isEditingMethod ? (
                                <i className="fa-solid fa-plus-circle fa-3x p-2 text-secondary cursor-pointer" onClick={() => {
                                    updateOptionMetods()
                                    setIsCreatingMethod(true)
                                }}></i>
                            ) : null}
                        </div>
                    </form>
            </div>
        </div>
    )

}
