import React, {useEffect, useRef, useState} from "react";
import {Customer} from "@models";
import {CustomerService} from "@services/CustomerService";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import {useNavigate} from "react-router-dom";
import useWindowDimensions from "@utils/WindowDimensions";

export function Customers() {

    const navigate = useNavigate();
    const { height } = useWindowDimensions();
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [open, setOpen] = React.useState(false);
    const [searchBar, setSearchBar] = React.useState(false);
    const [deletingId, setDeletingId] = useState<string>();
    const [query, setQuery] = useState<string>("");
    const [isLoaded, setIsLoaded] = useState(false);

    const handleOpen = (customerId: string) => {
        setOpen(true);
        setDeletingId(customerId);
    };
    const handleClose = () => {
        setOpen(false);
        setDeletingId(null);
    };
    const handleDelete = async () => {
        console.log("deletingId");
        await CustomerService.safeDelete(deletingId);
        getCustomers();
        setOpen(false);
    }
    const toggleSearchBar = async () => {
        setSearchBar(!searchBar);
    }
    const [pages, setPages] = useState<number>();
    const [hasMore, setHasMore] = useState<boolean>(true);
    let [page, setPage] = useState<number>(1);

    function getCustomers() {
        CustomerService.get({
                active: true,
                businessName: {
                    $regex : query,
                    $options : 'i'
                }
        })
        .then((resp: any) => {
            const customers = resp.data.docs as Customer[];
            setCustomers(customers);
            setHasMore(resp.data.hasNextPage);
            setPage(resp.data.nextPage)
        })
        .catch((err: any) => {
            console.error(err);
        });
    }


    const getNextCustomers = () =>  {
        CustomerService.get({query: {active: true}, option: {
            page: page++,
        }})
            .then((resp: any) => {
                setCustomers(customers.concat(resp.data.docs as Customer[]));
                setHasMore(resp.data.hasNextPage);
                setPage(resp.data.nextPage)
            })
            .catch((err: any) => {
                console.error(err);
            });
    }


    if (height >= (document.getElementById("customer")?.offsetHeight || 1080) * customers?.length && hasMore) {
         getNextCustomers()
    }

    useEffect( () => {
        getCustomers();
    }, [query]);



    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                style={{
                    borderRadius: 2
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    Sicuro di voler eliminare?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Questa operazione eliminerà il cliente dalla lista.
                        I suoi precedenti lavori non verranno eliminati.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleClose} className="flex items-center justify-around btn bg-transparent hover:bg-transparent rounded-full text-secondary border-0 py-2 px-4">
                        Annulla
                    </button>
                    <button onClick={handleDelete} className="flex items-center justify-around btn btn-error rounded-full bg-warning text-white border-0 py-2 px-4">
                        Elimina
                    </button>
                </DialogActions>
            </Dialog>
            <div className="content">
                <div className="flex justify-between items-center gap-2">
                    <h6 className='title title-margin text-left'>Clienti</h6>
                    <div className="w-full transition-all">
                        {
                            (searchBar)
                            ?
                                <label className="relative text-gray-600" htmlFor="query" id="expanded-bar">
                                    <span
                                        className=" text-gray-400 absolute inset-y-0 left-0 flex items-center pl-4 focus:outline-none focus:shadow-outline">
                                        <i className="fa fa-search"></i>
                                    </span>
                                    <input autoFocus name="query" id="query"
                                           onBlur={toggleSearchBar}
                                           className="input-field shadow-10 w-full rounded-full pl-10 focus:outline-none"
                                           placeholder="Cerca..." value={query} onChange={e => setQuery(e.target.value)}/>
                                </label>
                            :
                                <button onClick={toggleSearchBar}
                                    className="flex items-center justify-around btn btn-lg btn-primary rounded-full gap-2 text-white float-right">
                                    <i className="fa fa-search"></i>
                                </button>
                        }
                    </div>
                    <a onClick={() => navigate("/customers/manage")}
                       className="flex items-center justify-around btn btn-lg btn-primary rounded-full gap-2 mr-10 text-white">
                        <i className="fa-solid fa-user-plus"></i>Nuovo cliente
                    </a>
                </div>
                <div className="mt-10 pb-10" >
                    <InfiniteScroll
                        next={getNextCustomers}
                        hasMore={hasMore}
                        loader={
                            <button onClick={getNextCustomers} className="mt-5 btn btn-ghost">Carica altri clienti</button>
                        }
                        dataLength={10}
                        scrollableTarget="scrollableDiv"
                        className="mt-10 rounded-xl">
                        <div className="pb-10 h-scroll-2 rounded-xl" id="scrollableDiv">
                            {customers?.map((customer) => {
                                return (
                                    <div className="mt-4 px-10 py-5 std-radius flex justify-between items-center shadow-md bg-base-100" key={customer._id} id="customer" >
                                        <div className="text-2xl font-bold">{customer.businessName}</div>
                                        <div className="mt-1 std-radius flex justify-between items-center gap-2">
                                            <a onClick={() => navigate(`/customers/jobs?id=${customer._id}`)}
                                               className="h-10 w-30 btn btn-lg border-none flex items-center justify-around rounded-full btn-secondary text-white">
                                                <div className="m-1 text-xl">
                                                    <i className="fa-solid fa-list-check"></i>
                                                </div>
                                            </a>
                                            <a onClick={() => navigate(`/customers/manage?id=${customer._id}`)}
                                               className="h-10 w-30 btn btn-lg border-none flex items-center justify-around rounded-full btn-secondary text-white">
                                                <div className="m-1 text-xl">
                                                    <i className="fa-solid fa-user-pen"></i>
                                                </div>
                                            </a>
                                            <button onClick={() => handleOpen(customer._id)}
                                                    className="h-10 w-30 btn btn-lg border-none flex items-center justify-around rounded-full btn-warning text-white">
                                                <div className="m-1 text-xl">
                                                    <i className="fa-solid fa-trash-can"></i>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                );
                            })
                            }
                        </div>
                    </InfiniteScroll>
                </div>
            </div>
        </div>
    );
}
