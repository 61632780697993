import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {MaterialService} from "@services/MaterialService";
import {Customer, Job, Material, Method, Plate, Thickness} from "@models";
import {AddJob} from "@components/AddJob";
import {PlateService} from "@services/PlateService";
import {faker} from "@faker-js/faker";
import {CustomerService} from "@services/CustomerService";
import {PrintSavedJobs} from "@components/PrintSavedJobs";
import {Option} from "react-multi-select-component";

export function AddPlate() {
    const navigate = useNavigate();
    const [customers, setCustomers] = useState<Option[]>(null);
    const [materials, setMaterials] = useState<Material[]>(null);
    const [methods, setMethods] = useState<Method[]>(null);
    const [thicknesses, setThicknesses] = useState<Thickness[]>(null);
    const [disablePlateFields, setDisablePlateFields] = useState<boolean>(false);
    const [addJobField, setAddJobField] = useState<boolean>(false);
    const [jobs, setJobs] = useState<Job[]>([]);
    const [name, setName] = useState<string>(`Lavorazione ${(Math.random() > 0.5) ? faker.vehicle.model() : faker.lorem.word(7) }`);
    const [material, setMaterial] = useState<string>('');
    const [method, setMethod] = useState<string>('');
    const [thickness, setThickness] = useState<string>('');
    const [customersPage, setCustomersPage] = useState<number>(1)
    const [hasNextPageCustomers, setHasNextPageCustomers] = useState<boolean>(false);
    const [customerQuery, setCustomerQuery] = useState<string>("")

    const handleSubmitJobCallback = (data: Job) => {
        setAddJobField(false);
        setDisablePlateFields(true);
        setJobs([...jobs, data]);
    };

    const handleSubmitPlateCallback = (data: any, isDraft : boolean) => {
        const jobIds = jobs.map((job) => job._id);
        const plate = {...data, saved : !isDraft, jobs: jobIds } as Plate;

        PlateService.add(plate)
            .then((resp: any) => {
                console.log(resp.data);
                navigate('/plates');
            }).catch((err: any) => {
                console.error(err);
            });
    };


    useEffect( () => {
        MaterialService.getAll()
            .then((resp: any) => {
                const materials = resp.data as Material[];
                setMaterials(materials);
            })
            .catch((err: any) => {
                console.error(err);
            });
            getCustomers()
    }, [setMaterials, customerQuery]);

    function getCustomers() {
        CustomerService.get({
            businessName: {
                $regex : customerQuery,
                $options : 'i'
            }
        })
            .then((resp: any) => {
                const customersOptions : Option[] = [];
                const customers = resp.data.docs as Customer[];
                customers.forEach(customer => {
                    customersOptions.push({value: customer._id, label: `${customer.customerCode} | ${customer.businessName}`});
                })
                setCustomers(customersOptions);
                setHasNextPageCustomers(resp.data.hasNextPage)
                setCustomersPage(resp.data.nextPage)
            })
            .catch((err: any) => {
                console.error(err);
            });
    }

    const getNextCustomers = () => {
            if (!hasNextPageCustomers) {return}
            CustomerService.get({option : { page : customersPage}}).then((resp: any) => {
                const nextCustomersOption : Option[] = [];
                const nextCustomers = resp.data.docs as Customer[];
                nextCustomers.forEach(customer => {
                    nextCustomersOption.push({value: customer._id, label: `${customer.customerCode} | ${customer.businessName}}`});
                })
                setCustomers([...customers, ...nextCustomersOption]);
                setHasNextPageCustomers(resp.data.hasNextPage)
                setCustomersPage(resp.data.nextPage)
                setHasNextPageCustomers(resp.data.hasNextPage)
                setCustomersPage(resp.data.nextPage)
            })
            .catch((err: any) => {
                console.error(err);
            });
    }

    return (
        <div>
            <div className="content mx-10">
                <div className="flex items-center justify-between">
                    <div>
                        <label htmlFor="name">
                            <input id="name" name="name"
                                   className="title appearance-none bg-transparent mr-3 py-1 leading-tight focus:outline-none placeholder:underline"
                                   type="text" maxLength={100} placeholder="Nome Lavorazione" aria-label="Plate name"
                                   value={name} onChange={e => setName(e.target.value)}/>
                            <i className="fa-solid fa-pen fa-2x"></i>
                        </label>
                    </div>
                    <div className="flex">
                        <button onClick={() => handleSubmitPlateCallback({name, material, method, thickness}, true)} className=" flex items-center btn btn-lg btn-secondary rounded-full gap-2 mr-2 text-white">
                            <i className="fa-regular fa-circle-check"></i> Salva come bozza
                        </button>
                        <button onClick={() => handleSubmitPlateCallback({name, material, method, thickness}, false)} className=" flex items-center justify-around btn btn-lg btn-secondary rounded-full gap-2 mr-10 text-white">
                            <i className="fa-regular fa-circle-check"></i> Salva
                        </button>
                    </div>
                </div>

                <div className="mt-5 flex text-xl font-bold text-left gap-5 items-center">
                    <div className="flex flex-row items-center gap-5">
                        {/*<label className="select-none" htmlFor="material">Materiale:</label>*/}
                        <select id="material" name="material" disabled={disablePlateFields} defaultValue={''}
                                onChange={e => {
                                    setMaterial(e.target.value);
                                    setMethods(materials.find(material => material._id === e.target.value)?.methods);
                                }}
                                className="p-0 font-bold bg-transparent border-0 border-b-2 focus:outline-none focus:ring-0 focus:border-gray-200 peer disabled:opacity-100">
                            <option value={''} disabled>Materiale</option>
                            { materials?.map((item, index) => {
                                return (
                                    <option value={item._id} key={index}>{item.name}</option>
                                );
                            }) }
                        </select>
                    </div>

                    <div className="flex flex-row items-center gap-5">
                        {/*<label className="select-none" htmlFor="material">Metodo:</label>*/}
                        <select id="method" name="method"  disabled={disablePlateFields || !material} defaultValue={''}
                                onChange={e => {
                                    setMethod(e.target.value);
                                    setThicknesses(methods.find(method => method._id === e.target.value)?.thicknesses);
                                }}

                                className= {`p-0 font-bold bg-transparent border-0 border-b-2 focus:outline-none focus:ring-0 focus:border-gray-200 peer disabled:opacity-10 `}>
                            <option value={''} disabled>Metodo</option>
                            {methods?.map((item, index) => {
                                return (
                                    <option value={item._id} key={index}>{item.name}</option>
                                );
                            }) }
                        </select>
                    </div>

                    <div className="flex flex-row items-center gap-5">
                        {/*<label className="select-none" htmlFor="material">Spessore:</label>*/}
                        <select id="thickness" name="thickness" disabled={disablePlateFields || !method} defaultValue={''}
                                onChange={e => {setThickness(e.target.value)}}
                                className={`p-0 font-bold bg-transparent border-0 border-b-2 disabled:opacity-10 focus:outline-none focus:ring-0 focus:border-gray-200 peer disabled:opacity-100 `}>
                            <option value={''} disabled>Spessore</option>
                            { thicknesses?.map((item, index) => {
                                return (
                                    <option value={item._id} key={index}>{item.name}</option>
                                );
                            }) }
                        </select>
                    </div>
                </div>

                <PrintSavedJobs
                    jobs = {jobs}
                    setJobs = {setJobs}
                />

                { addJobField ? ( <AddJob setCustomerQuery={setCustomerQuery} getNextCustomers={getNextCustomers} hasNextCustomers={hasNextPageCustomers} customers={customers} parentForm={{name, material, method, thickness}} onSubmit={handleSubmitJobCallback} setAddJobField={setAddJobField}></AddJob> ) : ('') }

                <div className="flex justify-around mt-5">
                    <button type="submit" disabled={thickness == ""} className="btn btn-lg h-20 w-20 rounded-full border-0 text-black bg-input hover:bg-input disabled:bg-input/25"
                            onClick={() => setAddJobField(!addJobField)}>
                        <i className={`fa-solid ${(addJobField) ? "fa-minus" : "fa-plus"}  fa-2x text-black`}></i>
                    </button>
                </div>
            </div>
        </div>
    );
}
