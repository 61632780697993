import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Plate} from "@models";
import {PlateService} from "@services/PlateService";
import dateFormat from "dateformat";
import InfiniteScroll from "react-infinite-scroll-component";
import {JobService} from "@services/JobService";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";


export function Plates(props: {isSaved: boolean}) {

    const navigate = useNavigate();
    const [plates, setPlates] = useState<Plate[]>([]);
    const [page ,setPage] = useState<number>();
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [open, setOpen] = React.useState(false);
    const [searchBar, setSearchBar] = React.useState(false);
    const [deletingId, setDeletingId] = useState<string>();
    const [query, setQuery] = useState<string>("");

    const toggleSearchBar = async () => {
        setSearchBar(!searchBar);
    }

    const handleOpen = (plateId: string) => {
        setOpen(true);
        setDeletingId(plateId);
    };
    const handleClose = () => {
        setOpen(false);
        setDeletingId(null);
    };

    const handleDelete = async () => {
        await PlateService.safeDelete(deletingId);
        getPlates();
        setOpen(false);
    }

    const getPlates = () =>  {
        PlateService.get({
            query: {
                name: {
                    $regex : query,
                    $options : 'i'
                },
                saved : props.isSaved,
                active: true
            }
        }).then((resp: any) => {
            const plates = resp.data.docs as Plate[];
            setHasMore(resp.data.hasNextPage);
            setPage(resp.data.nextPage)
            setPlates(plates);
        })
        .catch((err: any) => {
            console.error(err);
        });
    }

    const getNextPlates = () =>  {
        PlateService.get({query: {saved : props.isSaved}, option: {page: page}})
            .then((resp: any) => {
                setPlates(plates.concat(resp.data.docs as Plate[]));
                setHasMore(resp.data.hasNextPage);
                setPage(resp.data.nextPage)
            })
            .catch((err: any) => {
                console.error(err);
            });
    }

    useEffect( () => {
        getPlates();
    }, [setPlates, props.isSaved, query]);

    return (
        <div>

            <Dialog
                open={open}
                onClose={handleClose}
                style={{
                    borderRadius: 2
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    Sicuro di voler eliminare?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Questa operazione eliminerà la lastra.
                        I lavori al suo interno saranno anch'essi eliminati.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleClose} className="flex items-center justify-around btn bg-transparent hover:bg-transparent rounded-full text-secondary border-0 py-2 px-4">
                        Annulla
                    </button>
                    <button onClick={handleDelete} className="flex items-center justify-around btn btn-error rounded-full bg-warning text-white border-0 py-2 px-4">
                        Elimina
                    </button>
                </DialogActions>
            </Dialog>
            <div className="content">
                <div className="flex justify-between items-center gap-2">
                    <h6 className='title title-margin text-left'>Lastre</h6>
                    <div className="w-full transition-all">
                        {
                            (searchBar)
                                ?
                                <label className="relative text-gray-600" htmlFor="query" id="expanded-bar">
                                    <span
                                        className=" text-gray-400 absolute inset-y-0 left-0 flex items-center pl-4 focus:outline-none focus:shadow-outline">
                                        <i className="fa fa-search"></i>
                                    </span>
                                    <input autoFocus name="query" id="query"
                                           onBlur={toggleSearchBar}
                                           className="input-field shadow-10 w-full rounded-full pl-10 focus:outline-none"
                                           placeholder="Cerca..." value={query} onChange={e => setQuery(e.target.value)}/>
                                </label>
                                :
                                <button onClick={toggleSearchBar}
                                        className="flex items-center justify-around btn btn-lg btn-primary rounded-full gap-2 text-white float-right">
                                    <i className="fa fa-search"></i>
                                </button>
                        }
                    </div>
                    <a onClick={() => navigate("/plates/add")}
                       className="text-whiet flex items-center justify-around btn btn-lg btn-primary rounded-full gap-2 mr-10 text-white">
                        <i className="fa-solid fa-file-circle-plus"></i>Nuova lastra
                    </a>
                </div>
                    <InfiniteScroll
                        next={getNextPlates}
                        hasMore={hasMore}
                        loader={
                            <button onClick={getNextPlates} className="mt-5 btn btn-ghost">Carica altre lastre</button>
                        }
                        dataLength={10}
                        scrollableTarget="scrollableDiv"
                        className="mt-10 rounded-xl"
                    >
                        <div className="pb-10 h-scroll rounded-xl" id="scrollableDiv">
                            {plates?.map((plate) => {
                                return (
                                    <div className="mt-4 px-10 py-5 std-radius flex justify-between items-center shadow-md bg-base-100">
                                        <div className="text-left">
                                            <div className="text-2xl font-bold">{plate.name}</div>
                                            <div>{dateFormat((plate.createdAt), 'dd/mm/yyyy')}</div>
                                        </div>
                                        <div className="mt-1  std-radius flex justify-between items-center gap-5">
                                            <div className="h-14 flex flex-col items-center justify-between gap-1">
                                                <div className="select-none text-s font-bold">Lavori</div>
                                                <div className="text-2xl font-bold text-secondary">{plate.jobs.length}</div>
                                            </div>

                                            <div className="h-14 flex flex-col items-center justify-between gap-1">
                                                <div className="select-none text-s font-bold">Importo</div>
                                                <div className="text-2xl font-bold text-secondary">€{plate.value}</div>
                                            </div>

                                            <a onClick={() => navigate(`/plates/view?id=${plate._id}`)}
                                               className="btn-secondary text-white h-10 w-30 btn btn-lg border-none flex items-center justify-around rounded-full">
                                                <div className="m-1 text-xl">
                                                    <i className="text-2xl fa-solid fa-circle-info"></i>
                                                </div>
                                            </a>
                                            <a onClick={() => handleOpen(plate._id)}
                                               className="btn-warning text-white h-10 w-30 btn btn-lg border-none flex items-center justify-around rounded-full">
                                                <div className="m-1 text-xl">
                                                    <i className="text-2xl fa-solid fa-trash"></i>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </InfiniteScroll>
            </div>
        </div>
    );
}
