import React, {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";

export default function Sidebar({user}:any) {
    const navigate = useNavigate();

    const logout = () => {
        localStorage.clear();
        navigate('/auth/login');
    };

    const [isPlatesMenuOpen, setIsPlatesMenuOpen] = useState<boolean>(false)

    let path = useLocation().pathname;

    return (
        <div className="drawer-side static  shadow-md">
            <label htmlFor="my-drawer" className="drawer-overlay flex mt-5">
                <img className="logo" style={{margin: 'auto', width: '200px'}} src="/fotomeccanica_logo.png" alt="Fotomeccanica Logo"/>
            </label>
            <ul className="menu p-2 overflow-y-auto w-80 text-base-content">
                <li className={path === "/dashboard" ? "bg-neutral rounded-2xl m-2  shadow-md" : "bg-white/0 rounded-2xl m-2"} >
                    <a style={{borderRadius: "1em"}} onClick={() => navigate("/dashboard")}><i className="fa-solid fa-house"/>Dashboard</a>
                </li>
                <li className={path === "/customers" ? "bg-neutral rounded-2xl m-2 shadow-md" : "bg-white/0 rounded-2xl m-2"} >
                    <a style={{borderRadius: "1em"}} onClick={() => navigate("/customers")} ><i className="fa-solid fa-users"/>Clienti</a>
                </li>
                <li className="rounded-2xl m-2">
                    <div className="flex-col">
                        <div className="flex justify-between w-full items-center" onClick={() => {
                            setIsPlatesMenuOpen(!isPlatesMenuOpen)
                            navigate("/plates")
                        }}>
                            <a style={{borderRadius: "1em"}} className="flex items-center gap-3"><i className=" fa-solid fa-print"/>Lastra</a>
                            <i className={isPlatesMenuOpen ? "fa-solid fa-chevron-up" : "fa-solid fa-chevron-down"}/>
                        </div>
                        {isPlatesMenuOpen ? (
                            <div className=" w-11/12 rounded-2xl m-2 flex-col ">
                                <li className={path === "/plates" ? "bg-neutral rounded-2xl m-2 shadow-md" : "bg-white/0 rounded-2xl m-2"}>
                                    <a style={{borderRadius: "1em"}} onClick={() => navigate("/plates")}><i className="fa-solid fa-list"></i>Tutte</a>
                                </li>
                                <li className={path === "/draftPlates" ? "bg-neutral rounded-2xl m-2 shadow-md" : "bg-white/0 rounded-2xl m-2"}>
                                    <a style={{borderRadius: "1em"}} onClick={() => navigate("/draftPlates")}><i className="fa-solid fa-pen-to-square"></i>Bozze</a>
                                </li>
                            </div>
                        ) : null}
                    </div>
                </li>
                <li className={path === "/materials" ? "bg-neutral rounded-2xl m-2 shadow-md" : "bg-white/0 rounded-2xl m-2"} >
                    <a style={{borderRadius: "1em"}} onClick={() => navigate("/materials")}><i className="fa-solid fa-ruler"></i>Materiali</a>
                </li>
            </ul>
            <div className="absolute bottom-0 flex p-2">
                <div className="select-none bg-neutral rounded-2xl m-2 px-4 py-3 relative text-center w-56 uppercase tracking-wider shadow-md">
                    <i className="absolute left-4 top-3.5 fa-solid fa-user"/>{user?.username || "Caricamento..."}
                </div>
                <div className="sidebar-bottom-btn w-12 flex items-center justify-center shadow-md" onClick={() => logout()}><i className="fa-solid fa-arrow-right-from-bracket"/></div>
            </div>
        </div>
    );
}
