import React, {useEffect, useState} from "react";
import {Customer, Material} from "@models";
import {MaterialService} from "@services/MaterialService";
import InfiniteScroll from "react-infinite-scroll-component";
import {useNavigate} from "react-router-dom";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {CustomerService} from "@services/CustomerService";

export function Materials(){

    const navigate = useNavigate();
    const [materials, setMaterials] = useState<Material[]>([])
    const [hasMore, setHasMore] = useState<boolean>(true)
    const [showMaterials, setShowMaterials] = useState<{[key: string]: boolean}>({});
    const [page, setPage] = useState<number>()
    const [materialId, setMaterialId] = useState<string>()
    const [openDeleteMaterial, setOpenDeleteMaterial] = useState(false);

    const getMaterials = () => {
        MaterialService.get({query : {isDeleted: false}, option : {
                populate: [
                    {
                        path: "methods",
                        model: "Method",
                        populate: "thicknesses"
                    },
                ]}
            }
        ).then((resp : any) => {
            setMaterials(resp?.data.docs as Material[]);
            setHasMore(resp.data.hasNextPage);
            setPage(resp.data.nextPage)
        }).catch((e : Error) => {
            console.error(e);
        })
    }

    const getNextMaterials = () => {
        MaterialService.get({query: {isDeleted: false}, option: {
                populate: [
                    {
                        path: "methods",
                        model: "Method",
                        populate: "thicknesses"
                    },
                ],
                page: page,
                limit : 30
            }})
            .then((resp: any) => {
                setMaterials(materials.concat(resp.data.docs as Material[]));
                setHasMore(resp.data.hasNextPage);
                setPage(resp.data.nextPage)
            })
            .catch((err: any) => {
                console.error(err);
            });
    }

    const handleOpenModalDeleteMaterial = (materialId : string) => {
        setMaterialId(materialId);
        setOpenDeleteMaterial(true);
    }

    const handleCloseModalDeleteMaterial = () => {
        setMaterialId("")
        setOpenDeleteMaterial(false)
    }

    const handleDeleteMaterial = () => {
        MaterialService.safeDelete(materialId).then(resp => {
            if(resp.status === 200) {
                getMaterials();
                setOpenDeleteMaterial(false);
            }
        });
    }

    useEffect( () => {
        getMaterials();
    }, [setMaterials]);

    return (
        <div>
            <Dialog
                open={openDeleteMaterial}
                onClose={handleDeleteMaterial}
                style={{
                    borderRadius: 2
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    Sicuro di voler eliminare?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Questa operazione eliminerà il materiale dalla lista.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseModalDeleteMaterial} className="flex items-center justify-around btn bg-transparent hover:bg-transparent rounded-full text-secondary border-0 py-2 px-4">
                        Annulla
                    </button>
                    <button onClick={handleDeleteMaterial} className="flex items-center justify-around btn btn-error rounded-full bg-warning text-white border-0 py-2 px-4">
                        Elimina
                    </button>
                </DialogActions>
            </Dialog>
            <div className="flex justify-between items-center gap-2">
                <h6 className='title title-margin text-left'>Materiali</h6>
                <a onClick={() => navigate("/materials/manage")}
                   className="flex items-center justify-around btn btn-lg btn-primary rounded-full gap-2 mr-10 text-white">
                <span >
                    <i className="fa-solid fa-ruler"></i>
                    <i className="fa-solid fa-plus fa-2xs pt-3"></i>
                </span>
                    Nuovo materiale
                </a>
            </div>
            <InfiniteScroll
                next={getNextMaterials}
                hasMore={hasMore}
                loader={
                    <button onClick={getNextMaterials} className="mt-5 btn btn-ghost">Carica altri materiali</button>
                }
                dataLength={30}
                scrollableTarget="scrollableDiv"
                className="mt-10 rounded-xl">
                <div className="pb-10 h-scroll-2 rounded-xl" id="scrollableDiv">
                    {materials?.map((material) => {
                        return (
                            <div key={`${material._id}`} className="cursor-pointer shadow-md bg-base-100 mt-4 p-4 rounded-2xl" onClick={() => {
                                setShowMaterials({
                                    ...showMaterials,
                                    [material._id]: !showMaterials[material._id]
                                });
                            }}>
                                <div className="w-full rounded-2xl">
                                    <div className="items-center text-2xl text-left font-bold flex justify-between">
                                        <span className="justify-start">{material.name}</span>
                                        <div>
                                            <a onClick={() => navigate(`/materials/manage?id=${material._id}`)}
                                               className="h-10 btn btn-lg border-none items-center rounded-full btn-secondary text-white mr-3">
                                                <div className="m-1 text-2xl">
                                                    <i className="fa-solid fa-pen-ruler"></i>
                                                </div>
                                            </a>
                                            <a onClick={() => handleOpenModalDeleteMaterial(material._id)}
                                               className="h-10 btn btn-lg border-none items-center rounded-full btn-error text-white mr-3">
                                                <div className="m-1 text-2xl">
                                                    <i className="fa-solid fa-trash"></i>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {showMaterials[material._id]  &&
                                        <div className="bg-white mt-4 rounded-lg">
                                            {material.methods.map(method => {
                                                return (
                                                    <div className="w-full p-3 flex-col">
                                                        <div className="text-2xl font-bold text-left">
                                                            - {method.name}
                                                        </div>
                                                        <div>
                                                            {method.thicknesses.map(thickness => {
                                                                return (
                                                                    <div className="text-l font-bold text-left ml-4">
                                                                        ● {thickness.name}
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    }
                                </div>
                            </div>
                        );
                    })
                    }
                </div>
            </InfiniteScroll>
        </div>
    )
}
