import http from "./axios";
import {Customer, Plate} from "@models";

const {REACT_APP_SERVER_URL} = process.env;

export const PlateService = {
    add: (plate: Plate) => {
        return http.post<Plate>(`${REACT_APP_SERVER_URL}/plates/add`, {
            ...plate
        }, {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }});
    },

    get: (data: any ) => {
        return http.post<Plate>(`${REACT_APP_SERVER_URL}/plates/query`,
            {...data}, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }});
    },

    safeDelete: (plateId: string) => {
        return http.put<Plate>(`${REACT_APP_SERVER_URL}/plates/safe-delete/${plateId}`, {
            active: false
        }, {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }});
    },
};
