import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {UserService} from "@services/UserService";
import '@assets/style/login.css';
import '@assets/style/constants.css';
import {useForm} from "react-hook-form";

export function Login() {
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [error, setError] = useState<string>('');

    const handleLogin = (data: any) => {
        setError('');
        UserService.auth(data.username, data.password).then( (resp: any) => {
            localStorage.setItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN, resp.data.token );
            navigate('/dashboard');
        }).catch( (err: any) => {
            console.error(err);
            setError('Credenziali errate');
            localStorage.clear();
        });
    };

    useEffect(() => {
        UserService.me().then( (resp: any) => {
            console.log(`User already logged in: ${resp.data}`);
            navigate("/dashboard");
        }).catch((err: any) => {
            console.log('No user logged in yet.');
            localStorage.clear();
        });
        // eslint-disable-next-line
    }, []);

    return (
    <div>
        <div className="centeredbox">
            <div className="login-container std-radius">
                <div className="divLogo">
                    <img className="logo" style={{margin: 'auto'}} src="/fotomeccanica_logo.png" alt="Fotomeccanica Logo"/>
                </div>
                <h3 className='font-bold title-margin' > Login</h3>

                <form onSubmit={handleSubmit(handleLogin)}>
                    <div className="mt-5">
                        <div className="flex items-center justify-between">
                            <label className="label title-margin" htmlFor="username">
                                <span className="label-text">Username</span>
                            </label>
                            <span className="text-error title-margin mt-1">{errors.username && "Campo obbligatorio"}</span>
                        </div>
                        <input id="username"
                               className="input input-md w-full rounded-full input-field"
                               type="text" maxLength={20}
                               {...register("username", { required: true, maxLength: 20 })}
                        />
                    </div>

                    <div className="mt-1">
                        <div className="flex items-center justify-between">
                            <label className="label title-margin" htmlFor="password">
                                <span className="label-text">Password</span>
                            </label>
                            <span className="text-error title-margin mt-1">{errors.password && "Campo obbligatorio"}</span>
                        </div>
                        <input id="password"
                               className="input input-md w-full rounded-full input-field"
                               type="password"
                               {...register("password", { required: true})}
                        />

                    </div>

                    <div className="flex items-center justify-center mt-10">
                        <input type="submit" className="btn btn-lg btn-primary rounded-full text-white" value="Accedi" />
                    </div>
                    <div>
                        <span className="text-error">{error}</span>
                    </div>
                </form>

            </div>
        </div>
    </div>);
}
