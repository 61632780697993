import http from "./axios";
import {Customer, Material} from "@models";

const {REACT_APP_SERVER_URL} = process.env;

export const MaterialService = {
    getAll: () => {
        return http.get<Material[]>(`${REACT_APP_SERVER_URL}/materials/all`, {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }
        });
    },

    get : (data : any) => {
        return http.post<Material[]>(`${REACT_APP_SERVER_URL}/materials/query`, { ...data}, {
                headers: {
                   "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
                }
        });
    },

    add: (material: Material) => {
        return http.post<Material>(`${REACT_APP_SERVER_URL}/materials/add`, {
            ...material
        }, {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }});
    },

    update: (material: Material, materialId: string) => {
        return http.put<Material>(`${REACT_APP_SERVER_URL}/materials/update/${materialId}`, {
            ...material
        }, {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }});
    },

    safeDelete: (materialId: string) => {
        return http.put<Material>(`${REACT_APP_SERVER_URL}/materials/update/${materialId}`, {
            isDeleted: true
        }, {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }});
    },
};
