import React from 'react';
import '@assets/style/App.css';
import {Navigate, Route, Routes} from "react-router-dom";
import PrivateRoute from "@utils/PrivateRoute";
import {Customers, Dashboard, Login, Plates, ManageCustomer, AddPlate, Materials, ManageMaterials} from "@pages";
import {ViewPlate} from "./pages/ViewPlate";
import {CustomerJobs} from "./pages/CustomerJobs";

function App() {

  return (
    <div className="App shadow-md bg-base-100">
      <Routes>
          <Route path='*' element={<Navigate to={'/auth/login'}/>}/>
          <Route element={<PrivateRoute />}>
              <Route path="/dashboard" element={<Dashboard/>}/>
              <Route path="/customers" element={<Customers/>}/>
              <Route path="/customers/manage" element={<ManageCustomer/>}/>
              <Route path="/customers/jobs" element={<CustomerJobs/>}/>
              <Route path="/plates" element={<Plates isSaved={true}/>}/>
              <Route path="/draftPlates" element={<Plates isSaved={false}/>}/>
              <Route path="/plates/add" element={<AddPlate/>}/>
              <Route path="/plates/view" element={<ViewPlate/>}/>
              <Route path="/materials" element={<Materials/>}/>
              <Route path="/materials/manage" element={<ManageMaterials/>}/>

          </Route>
          <Route path='/auth/login' element={<Login/>} />
      </Routes>
    </div>
  );
}

export default App;
