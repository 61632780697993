import http from "./axios";
import {Method} from "@models";

const {REACT_APP_SERVER_URL} = process.env;

export const MethodService = {

    getAll: () => {
        return http.get<Method[]>(`${REACT_APP_SERVER_URL}/methods/all`, {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }
        });
    },

    get : (data : any) => {
        return http.post<Method[]>(`${REACT_APP_SERVER_URL}/methods/query`, { ...data}, {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }
        });
    },

    add: (method: Method) => {
        return http.post<Method>(`${REACT_APP_SERVER_URL}/methods/add`, {
            ...method
        }, {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }});
    },

    update: (method: Method, methodId: string) => {
        return http.put<Method>(`${REACT_APP_SERVER_URL}/methods/update/${methodId}`, {
            ...method
        }, {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }});
    },

};
