import http from "./axios";
import {Thickness} from "@models";

const {REACT_APP_SERVER_URL} = process.env;

export const ThicknessService = {

    getAll: () => {
        return http.get<Thickness[]>(`${REACT_APP_SERVER_URL}/thickness/all`, {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }
        });
    },

    get : (data : any) => {
        return http.post<Thickness[]>(`${REACT_APP_SERVER_URL}/thickness/query`, { ...data}, {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }
        });
    },

    add: (thickness: Thickness) => {
        return http.post<Thickness>(`${REACT_APP_SERVER_URL}/thickness/add`, {
            ...thickness
        }, {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }});
    },
};
