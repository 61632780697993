import {UserService} from "@services/UserService";
import React, {useEffect, useState} from "react";
import {Navigate, Outlet, useLocation, useNavigate} from "react-router-dom";
import Sidebar from "@components/Sidebar";
import {User} from "@models";

// Checks if user is logged in and set page layout: sidebar and content
export default function PrivateRoute() {
    const [user, setUser] = useState<User>(null);

    const navigate = useNavigate();
    const location = useLocation();
    const auth = localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN);

    useEffect(() => {
        UserService.me().then( (resp: any) => {
            console.log(resp.data);
            setUser(resp.data);
            // localStorage.setItem(process.env.REACT_APP_LOCALSTORAGE_USER, JSON.stringify(resp.data));
        }).catch((err: any) => {
            console.error(err);
            setUser(null);
            localStorage.clear();
            navigate("/auth/login");
        });
        // eslint-disable-next-line
    }, [location.pathname]);

    return (
        auth ? (
            <div style={{display: 'flex'}}>
                <Sidebar user={user}/>
                <div className="content-panel w-80 bg-neutral">
                    { !location.pathname.includes('dashboard') &&
                        <div className="flex align-start px-10">
                            <button onClick={() => navigate(-1)} className="select-none">
                                <i className="fa-solid fa-arrow-left text-left"></i>&nbsp;
                                <span className="hover:underline">Indietro</span>
                            </button>
                        </div>
                    }
                    <Outlet/>
                </div>
            </div>
        ) : (
            <Navigate to={'/auth/login'}/>
        )
    );
}

