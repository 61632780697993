import http from "./axios";
import {Customer, Material, Plate} from "@models";

const {REACT_APP_SERVER_URL} = process.env;

export const CustomerService = {
    add: (customer: Customer) => {
        return http.post<Customer>(`${REACT_APP_SERVER_URL}/customers/add`, {
            ...customer
        }, {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }});
    },

    update: (customer: Customer, customerId: string) => {
        return http.put<Customer>(`${REACT_APP_SERVER_URL}/customers/update/${customerId}`, {
            ...customer
        }, {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }});
    },

    getAll: () => {
        return http.get<Material[]>(`${REACT_APP_SERVER_URL}/customers/all`, {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }
        });
    },

    get: (data: any) => {
        return http.post<Plate>(`${REACT_APP_SERVER_URL}/customers/query`,{...data}, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }});
    },

    delete: (customerId: string) => {
        return http.delete<Customer>(`${REACT_APP_SERVER_URL}/customers/delete/${customerId}`,  {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }});
    },

    safeDelete: (customerId: string) => {
        return http.put<Customer>(`${REACT_APP_SERVER_URL}/customers/update/${customerId}`, {
            active: false
        }, {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }});
    },
};
