import http from "./axios";
import {Customer, Job} from "@models";

const {REACT_APP_SERVER_URL} = process.env;

export const JobService = {
    add: (form: FormData) => {
        return http.post<Job>(`${REACT_APP_SERVER_URL}/jobs/add`, form, {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }});
    },
    getByCustomer: (customerId: string, page: number) => {
        return http.post<Job>(`${REACT_APP_SERVER_URL}/jobs/query`,
            {
                query: {
                    customer: customerId,
                    saved: true
                },
                option: {
                    page: page,
                    populate: "file material method thickness"
                }},
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
                }
            });
    },


    get: (plateId: string) => {
        return http.post<Job>(`${REACT_APP_SERVER_URL}/jobs/query`,
            {query: {plate: plateId}}, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
                }});
    },

    getAll: (body: any) => {
        return http.post<Job>(`${REACT_APP_SERVER_URL}/jobs/query`,
            {...body}, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
                }});
    },

    setPaid: (id: string, value: boolean) => {
        return http.put<Job>(`${REACT_APP_SERVER_URL}/jobs/update/${id}`,
            {paid: value}, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
                }});
    },

    safeDelete: (jobId: string) => {
        return http.put<Job>(`${REACT_APP_SERVER_URL}/jobs/update/${jobId}`, {
            active: false
        }, {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }});
    },

    delete: (jobId: string) => {
        return http.delete<Job>(`${REACT_APP_SERVER_URL}/jobs/delete/${jobId}`, {
            headers: {
                "Authorization": localStorage.getItem(process.env.REACT_APP_LOCALSTORAGE_TOKEN) ?? ""
            }});
    },
};
